import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Social Media Imports
// actions
import { loginUser, socialLogin, resetLoginFlag } from "../../store/actions";

import logoLight from "../../assets/images/logo_asha.png";
//Import config
import { facebook, google } from "../../config";
import {APIClient} from "../../helpers/api_helper";
//import images

const Login = (props) => {
    const dispatch = useDispatch();
    const api = new APIClient();
    const { user } = useSelector(state => ({
        user: state.Account.user,
    }));

    const [userLogin, setUserLogin] = useState([]);

    useEffect(() => {
        if (user && user) {
            setUserLogin({
                username: user.user.username,
                password: user.user.confirm_password
            });
        }
    }, [user]);

    async function postLoginApi(data) {
        try{
            const response = await api.create(`/auth`, data);
            console.log(response);
            return response;
            //setNoteList(res);
            //setPagination(res.pagination);
            //setisNotesSuccess(true);
        }catch (e){
            console.log(e);
            //setError('Invalid Request');
            //setisNotesSuccess(false);
        }
    }

    function postLogin(data){
        postLoginApi(data).then((response) => {
            if(response.message){
                alert(response.message);
            }else{
                localStorage.setItem("user", JSON.stringify(response));
                //response = JSON.stringify(response);
                console.log(response);
                var expiry = new Date(response.expires_in)
                //document.cookie = 'token=' + response.token + '; expires=' + expiry.toGMTString() + ';';
                localStorage.setItem("token", response.token);
                if (response.token !== null) {
                    window.location.href = "/switch";
                }else {
                    alert("Login Error");
                }
            }

        });
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            username: userLogin.username  || '',
            password: userLogin.password ||  '',
        },
        validationSchema: Yup.object({
            username: Yup.string().required("Please Enter Your Email"),
            password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: (values) => {
            postLogin(values);
        }
    });

    const { error } = useSelector(state => ({
        error: state.Login.error,
    }));


    const signIn = (res, type) => {
        if (type === "google" && res) {
            console.log("res", res)
            const postData = {
                name: res.profileObj.name,
                email: res.profileObj.email,
                token: res.tokenObj.access_token,
                idToken: res.tokenId,
            };
            dispatch(socialLogin(postData, props.history, type));
        } else if (type === "facebook" && res) {
            const postData = {
                name: res.name,
                email: res.email,
                token: res.accessToken,
                idToken: res.tokenId,
            };
            dispatch(socialLogin(postData, props.history, type));
        }
    };

    const togglePassword = () => {
        let  ptype = document.getElementById("password").type;
        if(ptype === "text"){
            document.getElementById("password").type="password";
        }else{
            document.getElementById("password").type="text";
        }

    }

    //handleGoogleLoginResponse
    // const googleResponse = response => {
    //     console.log("response", response)
    //     signIn(response, "google");
    // };

    //handleTwitterLoginResponse
    // const twitterResponse = e => {}

    //handleFacebookLoginResponse
    // const facebookResponse = response => {
    //     signIn(response, "facebook");
    // };

    useEffect(() => {
        setTimeout(() => {
            dispatch(resetLoginFlag());
        }, 3000);
    }, [dispatch, error]);

    document.title = "Trade Manager | AshaCapital";
    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content login_background" >
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            {/*{<img src={logoLight} alt="" height="20" />}*/}
                                        </Link>
                                    </div>
                                    {/*<p className="mt-3 fs-15 fw-medium">Premium Admin & Dashboard Template</p>*/}
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            {<img src={logoLight} alt="Asha Capital Logo" height={"60px"} />}
                                            <h5 className="text-muted" style={{paddingTop:"10px"}}>Welcome Back !</h5>
                                            <p className="text-muted">Sign in</p>
                                        </div>
                                        {error && error ? (<Alert color="danger"> {error} </Alert>) : null}
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                                action="#">

                                                <div className="mb-3">
                                                    <Label htmlFor="username" className="form-label">Email</Label>
                                                    <Input
                                                        name="username"
                                                        className="form-control"
                                                        placeholder="Enter email"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.username || ""}
                                                        invalid={
                                                            validation.touched.username && validation.errors.username ? true : false
                                                        }
                                                    />
                                                    {validation.touched.username && validation.errors.username ? (
                                                        <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <div className="float-end">
                                                        <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                                                    </div>
                                                    <Label className="form-label" htmlFor="password-input">Password</Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input id={"password"}
                                                            name="password"
                                                            value={validation.values.password || ""}
                                                            type="password"
                                                            className="form-control pe-5"
                                                            placeholder="Enter Password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.password && validation.errors.password ? true : false
                                                            }
                                                        />
                                                        {validation.touched.password && validation.errors.password ? (
                                                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                        ) : null}
                                                        <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" onClick={togglePassword} type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                                                    </div>
                                                </div>

                                                <div className="form-check">
                                                    <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                    <Label className="form-check-label" htmlFor="auth-remember-check">Remember me</Label>
                                                </div>

                                                <div className="mt-4">
                                                    <Button className="btn btn-secondary w-100" type="submit">Sign In</Button>
                                                </div>


                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="mt-4 text-center">
                                    <p className="mb-0">Don't have an account ? <Link to="/register" className="fw-semibold text-muted text-decoration-underline"> Signup </Link> </p>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(Login);