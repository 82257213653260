import React, { useEffect } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Button } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// action
import { registerUser, apiError, resetRegisterFlag } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useHistory } from "react-router-dom";

//import images
import logoLight from "../../assets/images/logo_asha.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import {APIClient} from "../../helpers/api_helper";

const VerifyUser = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const api = new APIClient();

    async function postLoginApi(data) {
        try{
            const response = await api.create(`/otp-verify`, data);
            console.log(response);
            return response;
            //setNoteList(res);
            //setPagination(res.pagination);
            //setisNotesSuccess(true);
        }catch (e){
            console.log(e);
            //setError('Invalid Request');
            //setisNotesSuccess(false);
        }
    }

    function postSignup(data){
        postLoginApi(data).then((response) => {
            if (response.email !== null) {
                props.history.push("/login");
            }else {
                alert("Signup Error");
            }
        });
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: '',
            otp: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            otp: Yup.string().required("Please Enter OTP"),
        }),
        onSubmit: (values) => {
            console.log(values);
            postSignup(values);
            //dispatch(registerUser(values));
        }
    });

    const { error, registrationError, success } = useSelector(state => ({
        registrationError: state.Account.registrationError,
        success: state.Account.success,
        error: state.Account.error
    }));

    useEffect(() => {
        dispatch(apiError(""));
    }, [dispatch]);

    useEffect(() => {
        if (success) {
            setTimeout(() => history.push("login"), 3000);
        }

        setTimeout(() => {
            dispatch(resetRegisterFlag());
        }, 3000);

    }, [dispatch, success, error, history]);

    document.title = "Verify User | Asha Capital";

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content login_background">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            {/*<img src={logoLight} alt="" height="20" />*/}
                                        </Link>
                                    </div>
                                    {/*<p className="mt-3 fs-15 fw-medium">Premium Admin & Dashboard Template</p>*/}
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">

                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            {<img src={logoLight} alt="Databus Logo" height={"60px"}/>}
                                            <h5 className="text-muted" style={{paddingTop:"10px"}}>Verify User</h5>
                                            <p className="text-muted">Verify account with email otp</p>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                                className="needs-validation" action="#">

                                                {success && success ? (
                                                    <>
                                                        {toast("Your Redirect To Login Page...", { position: "top-right", hideProgressBar: false, className: 'bg-success text-white', progress: undefined, toastId: "" })}
                                                        <ToastContainer autoClose={2000} limit={1} />
                                                        <Alert color="success">
                                                            Verified User Successfully and Your Redirect To Login Page...
                                                        </Alert>
                                                    </>
                                                ) : null}

                                                {error && error ? (
                                                    <Alert color="danger"><div>
                                                        {/* {registrationError} */}
                                                        Email has been Register Before, Please Use Another Email Address... </div></Alert>
                                                ) : null}

                                                <div className="mb-3">
                                                    <Label htmlFor="useremail" className="form-label">Email <span className="text-danger">*</span></Label>
                                                    <Input
                                                        id="email"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Enter email address"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true : false
                                                        }
                                                    />
                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.email}</div></FormFeedback>
                                                    ) : null}

                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="usermobile" className="form-label">OTP <span className="text-danger">*</span></Label>
                                                    <Input
                                                        id="otp"
                                                        name="otp"
                                                        className="form-control"
                                                        placeholder="Enter Mobile Number"
                                                        type="otp"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.otp || ""}
                                                        invalid={
                                                            validation.touched.otp && validation.errors.otp ? true : false
                                                        }
                                                    />
                                                    {validation.touched.otp && validation.errors.otp ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.otp}</div></FormFeedback>
                                                    ) : null}

                                                </div>

                                                {/*<div className="mb-4">*/}
                                                {/*    <p className="mb-0 fs-12 text-muted fst-italic">By registering you agree to the Velzon*/}
                                                {/*        <Link to="#" className="text-primary text-decoration-underline fst-normal fw-medium">Terms of Use</Link></p>*/}
                                                {/*</div>*/}

                                                <div className="mt-4">
                                                    <button className="btn btn-secondary w-100" type="submit">Verify User</button>
                                                </div>

                                                {/*<div className="mt-4 text-center">*/}
                                                {/*    <div className="signin-other-title">*/}
                                                {/*        <h5 className="fs-13 mb-4 title text-muted">Create account with</h5>*/}
                                                {/*    </div>*/}

                                                {/*    <div>*/}
                                                {/*        <button type="button" className="btn btn-primary btn-icon waves-effect waves-light"><i className="ri-facebook-fill fs-16"></i></button>{" "}*/}
                                                {/*        <button type="button" className="btn btn-danger btn-icon waves-effect waves-light"><i className="ri-google-fill fs-16"></i></button>{" "}*/}
                                                {/*        <button type="button" className="btn btn-dark btn-icon waves-effect waves-light"><i className="ri-github-fill fs-16"></i></button>{" "}*/}
                                                {/*        <button type="button" className="btn btn-info btn-icon waves-effect waves-light"><i className="ri-twitter-fill fs-16"></i></button>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-4 text-center">
                                    <p className="mb-0">Already have an account ? <Link to="/login" className="fw-semibold text-muted text-decoration-underline"> Signin </Link> </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default VerifyUser;
