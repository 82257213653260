import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

const About = () => {
    return (
        <React.Fragment>
            <section className="section" id="about">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <div className="text-center mb-5">
                                <h1 className="mb-3 ff-secondary fw-semibold lh-base">Welcome to ASHA CAPITAL</h1>
                                <p className="text-muted">
                                    We are a leading provider of professional Forex fund management services. With 7 years of experience and a team of expert traders, we help clients achieve their financial goals through strategic investments in the forex market.
                                </p>
                                <p className="text-muted">
                                    Our mission is to provide exceptional fund management services to individuals and businesses, ensuring maximum returns on their investments. We pride ourselves on our commitment to transparency, reliability, and delivering consistent profits for our clients.
                                </p>
                                <h1 className="mb-3 ff-secondary fw-semibold lh-base">Who We Are</h1>
                                <p className="text-muted">
                                    At Forex Fund Management Service, we are a team of dedicated professionals with extensive experience in the forex market. We specialize in providing comprehensive fund management services to clients worldwide. With our expertise and deep understanding of the forex industry, we aim to help our clients achieve their financial goals through strategic investment approaches and risk management.</p>
                                <p className="text-muted">
                                    We believe in transparency, integrity, and a client-centric approach. Our core values drive everything we do, and we strive to build long-term relationships based on trust and mutual success. With a focus on delivering exceptional results, we aim to exceed your expectations and help you achieve your financial goals.
                                </p>
                                <h1 className="mb-3 ff-secondary fw-semibold lh-base">What We Do</h1>
                                <p className="text-muted">
                                    Our primary focus is on delivering exceptional forex fund management services tailored to the unique needs and objectives of our clients. We offer personalized investment solutions designed to optimize returns while mitigating risks in the forex market. Our team of skilled traders and analysts employ advanced trading strategies and cutting-edge technologies to execute trades and monitor market conditions in real-time.
                                </p>
                                <p className="text-muted">
                                    With our comprehensive range of services, we strive to create a seamless and hassle-free experience for our clients. Whether you are an individual investor or a corporate entity, we are committed to delivering transparency, reliability, and consistent performance in managing your funds. Our dedication to excellence and client satisfaction sets us apart in the industry. </p>
                            </div>
                        </Col>
                    </Row>


                </Container>
            </section>
        </React.Fragment>
    );
};

export default About;