import React from "react";
import {Container, Row} from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import MyReferralsList from "./MyReferralsList";

const MyReferralsView = () => {
    return (
        <React.Fragment>
            <div className="page-content">

                <Container fluid>
                    <BreadCrumb title="My Referrals" pageTitle="Asha Capitals" />
                    <MyReferralsList />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default MyReferralsView;