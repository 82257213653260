import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap';
//Import Flatepicker
import Select from "react-select";
import {APIClient} from "../../connector/client";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { FaStar } from "react-icons/fa";

const RatingForm = (props) => {
    const api = new APIClient();
    const [star_count, setStarCount] = useState(1);
    const [feedback, setFeedback] = useState('');



    const handleCancel = (event) => {
        props.history.push("/my-feedbacks");
    }

    async function postApi() {
        var data = {};
        data['star_count'] = star_count;
        data['feedback'] = feedback;
        try{
            const response = await api.create(`/rating`, data);
            console.log(response);
            return response;
        }catch (e){
            console.log(e);
        }
    }

    function postData(){
        postApi().then((response) => {
            props.history.push("/my-feedbacks");

        });
    }

    const handleSubmit = (event) =>{
        postData();

    }



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Add Rating" pageTitle="Ratings" />
                    <form id="myForm" method="post" action={'#'} encType="multipart/form-data" onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                        return false;
                    }}>
                        <Row>
                            <Col lg={8}>

                                <Row>
                                    <Card>
                                        <CardHeader>
                                            <h5 className="card-title mb-0">Rating Box</h5>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Rating Stars</Label>
                                                        <select value={star_count} name={'star_count'} className="form-select" data-choices data-choices-search-false onChange={event => setStarCount(event.target.value)}>
                                                            <option value={1}><div>1 Star</div></option>
                                                            <option value={2}>2 Star</option>
                                                            <option value={3}>3 Star</option>
                                                            <option value={4}>4 Star</option>
                                                            <option value={5}>5 Star</option>
                                                        </select>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Feedback</Label>
                                                        <textarea className="form-control" name={"feedback"} value={feedback} placeholder="Enter Feedback" onChange={event => setFeedback(event.target.value)}></textarea>

                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="mb-4">
                                            <button onClick={event => handleCancel(event)} type="button" className="btn btn-danger w-sm me-1">Cancel</button>
                                            <button type="submit" className="btn btn-success w-sm me-1">Submit</button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    </form>


                </Container>
            </div>
        </React.Fragment>
    );
};

export default RatingForm;