import React, {useEffect, useState} from "react";
import {APIClient} from "../../helpers/api_helper";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {Card, CardBody, CardHeader, Col, Container, Input, Label, Row} from "reactstrap";
import Select from "react-select";

const PlanView = (props) => {
    const [subscribeId, setSubscribeId] = useState(props.match.params.planId);
    const api = new APIClient();
    const [projectList, setProjectList] = useState({});
    const [error, setError] = useState({});
    let [isProjectsSuccess, setisProjectsSuccess] = useState(true);
    async function getProjectsApi() {
        try{
            console.log(subscribeId);
            const res = await api.get(`/plan-details?plan_id=${subscribeId}`);
            console.log(res);
            setProjectList(res);
            const fields = {};
            fields['plan_id'] = res.plan_id
            fields['type_id'] = res.type_id
            fields['paymode'] = 'bank';
            setInputFields(fields);
            setisProjectsSuccess(true);
        }catch (e){
            console.log(e);
            setError('Invalid Request');
            setisProjectsSuccess(false);
        }
    }

    function getProjectsList(){
        getProjectsApi().then();
    }
    useEffect(() => {
        console.log('page');
        getProjectsList();
    }, []);

    const SingleOptions = [
        { value: 'Watches', label: 'Watches' },
        { value: 'Headset', label: 'Headset' },
        { value: 'Sweatshirt', label: 'Sweatshirt' },
        { value: '20% off', label: '20% off' },
        { value: '4 star', label: '4 star' },
    ];

    const [selectedMulti, setselectedMulti] = useState(null);

    const handleMulti = (selectedMulti) => {
        setselectedMulti(selectedMulti);
    }

    //Dropzone file upload
    const [selectedFiles, setselectedFiles] = useState([]);
    const [files, setFiles] = useState([]);

    const [inputFields, setInputFields] = useState({});
    const [attachments, setAttachments] = useState();

    const handleInputChange = (event) => {
        inputFields[event.target.name] = event.target.value;
        //inputFields['full_name'] = inputFields['first_name'] + ' ' + inputFields['last_name'];
        setInputFields(inputFields);
        console.log(inputFields);
    }

    const handleFileChange = (event) =>{
        setAttachments(event.target.files[0]);
    }

    async function postApi(data) {
        var formData = new FormData();
        formData.append("data", JSON.stringify(inputFields));
        console.log(attachments);
        formData.append('file', attachments);
        try{
            const response = await api.create(`/subscribe`, formData);
            console.log(response);
            return response;
        }catch (e){
            console.log(e);
        }
    }

    function postData(data){
        postApi(data).then((response) => {
            props.history.push("/offer-plans");

        });
    }

    const handleSubmit = (event) =>{
        var data = new FormData();
        data.append("data", JSON.stringify(inputFields));
        data.append('file', attachments);
        postData(data);

    }



    const handleCancel = (event) => {
        props.history.push("/users");
    }

    async function updateApi(status) {
        try{
            const response = await api.get(`/update-plan-status?status=`+status+`&plan_id=`+subscribeId);
            console.log(response);
            return response;
        }catch (e){
            console.log(e);
        }
    }

    const updateStatus = (status) =>{
        updateApi(status).then((res)=>{
            getProjectsList();
        });
    }

    const handleActivate = (event) => {
      updateStatus('activate');
    }

    const handleDeactivate = (event) => {
        updateStatus('deactivate')
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title=" Plan Details" pageTitle="Plans" />
                    <form id="myForm" method="post" action={'#'} encType="multipart/form-data" onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                        return false;
                    }}>
                        <Row>
                            <Col lg={12}>
                                <Row>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col></Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        {projectList.is_active ? (
                                                            <button onClick={event => handleDeactivate(event)} type="button" className="btn btn-danger w-sm me-1">Deactivate</button>
                                                        ) : (
                                                            <button onClick={event => handleActivate(event)} type="button" className="btn btn-success w-sm me-1">Activate</button>
                                                        )}

                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Plan Name</Label> : {projectList.plan_name}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Plan Type</Label> : {projectList.type_name}
                                                    </div>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Description</Label> : {projectList.description}
                                                    </div>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Interest Info</Label> : {projectList.interest_info}

                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Payout</Label>: {projectList.payout}
                                                    </div>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Amount</Label> : {projectList.amount}

                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">EMI Amount</Label>: {projectList.emi_amount}
                                                    </div>
                                                </Col>

                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Row>
                            </Col>
                        </Row>
                    </form>
                </Container>
            </div>
        </React.Fragment>
    );

}

export default PlanView;