import {Card, Col, Collapse, Container, Row} from "reactstrap";
import faqImg from "../../assets/images/faq-img.png";
import classnames from "classnames";
import React, {useState} from "react";

const IncomeType = () => {
    const [col1, setcol1] = useState(true)
    const [col2, setcol2] = useState(false)
    const [col3, setcol3] = useState(false)
    const [col4, setcol4] = useState(false)

    const [col5, setcol5] = useState(false)
    const [col6, setcol6] = useState(true)
    const [col7, setcol7] = useState(false)
    const [col8, setcol8] = useState(false)

    const [col9, setcol9] = useState(true)
    const [col10, setcol10] = useState(false)
    const [col11, setcol11] = useState(false)
    const [col12, setcol12] = useState(false)


    const t_col1 = () => {
        setcol1(!col1)
        setcol2(false)
        setcol3(false)
        setcol4(false)

    }

    const t_col2 = () => {
        setcol2(!col2)
        setcol1(false)
        setcol3(false)
        setcol4(false)

    }

    const t_col3 = () => {
        setcol3(!col3)
        setcol1(false)
        setcol2(false)
        setcol4(false)

    }

    const t_col4 = () => {
        setcol4(!col4)
        setcol1(false)
        setcol2(false)
        setcol3(false)
    }

    const t_col5 = () => {
        setcol5(!col5)
        setcol6(false)
        setcol7(false)
        setcol8(false)

    }

    const t_col6 = () => {
        setcol6(!col6)
        setcol5(false)
        setcol7(false)
        setcol8(false)

    }

    const t_col7 = () => {
        setcol7(!col7)
        setcol6(false)
        setcol5(false)
        setcol8(false)

    }

    const t_col8 = () => {
        setcol8(!col8)
        setcol5(false)
        setcol6(false)
        setcol7(false)
    }

    const t_col9 = () => {
        setcol9(!col9)
        setcol10(false)
        setcol11(false)
        setcol12(false)

    }

    const t_col10 = () => {
        setcol10(!col10)
        setcol11(false)
        setcol12(false)
        setcol9(false)

    }

    const t_col11 = () => {
        setcol11(!col11)
        setcol9(false)
        setcol10(false)
        setcol12(false)

    }

    const t_col12 = () => {
        setcol12(!col12)
        setcol9(false)
        setcol10(false)
        setcol11(false)
    }

    return(
        <React.Fragment>
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card className="rounded-0 mx-n4 mt-n4 border-top" style={{backgroundColor:"rgba(94, 53, 177, 0.18)"}}>
                            <div className="px-4">
                                <Row>
                                    <Col xxl={5} className="align-self-center">
                                        <div className="py-4">
                                            <h4 className="display-6 " style={{color:"#5e35b1"}}>Income Type</h4>
                                            <p className=" fs-15 mt-3" style={{color:"#5e35b1"}}>You can always contact us or email us. We will answer you shortly!</p>
                                            {/*<div className="hstack flex-wrap gap-2">*/}
                                            {/*    <button type="button" className="btn btn-primary btn-label rounded-pill"><i className="ri-mail-line label-icon align-middle rounded-pill fs-16 me-2"></i> Email Us</button>*/}
                                            {/*    <button type="button" className="btn btn-info btn-label rounded-pill"><i className="ri-twitter-line label-icon align-middle rounded-pill fs-16 me-2"></i> Send Us Tweet</button>*/}
                                            {/*</div>*/}
                                        </div>
                                    </Col>
                                    <div className="col-xxl-3 ms-auto">
                                        <div className="mb-n5 pb-1 faq-img d-none d-xxl-block">
                                            <img src={faqImg} alt="" className="img-fluid"/>
                                        </div>
                                    </div>
                                </Row>
                            </div>
                        </Card>
                        <Row className="justify-content-evenly">
                            <Col lg={6}>
                                <div className="mt-3">
                                    <div className="d-flex align-items-center mb-2">
                                        <div className="flex-shrink-0 me-1">
                                            <i className="ri-question-line fs-24 align-middle  me-1" style={{"color": "#5e35b1"}}></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="fs-16 mb-0 fw-semibold">Income Type</h5>
                                        </div>
                                    </div>

                                    <div className="accordion accordion-border-box" id="genques-accordion">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="genques-headingOne">
                                                <button
                                                    className={classnames(
                                                        "accordion-button",
                                                        "fw-medium",
                                                        { collapsed: !col1 }
                                                    )}
                                                    type="button"
                                                    onClick={t_col1}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    What is Investment Income ?
                                                </button>
                                            </h2>
                                            <Collapse isOpen={col1} className="accordion-collapse">
                                                <div className="accordion-body">
                                                    Investment income refers to the earnings generated from various types of investments, including stocks, bonds, real estate, and other financial assets. It plays a crucial role in building wealth and achieving financial goals, such as funding retirement or meeting other long-term financial objectives.

                                                    Investment income is essential for investors as it contributes to their overall financial well-being and helps in achieving long-term financial goals. It can also serve as a passive income source, providing a steady cash flow and supplementing other income streams. Investment income comes in various forms, including dividends, interest, capital gains, rental income, royalties, and annuities. Each type of investment income has its characteristics and tax implications.
                                                </div>
                                            </Collapse>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="genques-headingTwo">
                                                <button
                                                    className={classnames(
                                                        "accordion-button",
                                                        "fw-medium",
                                                        { collapsed: !col2 }
                                                    )}
                                                    type="button"
                                                    onClick={t_col2}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    Example of Investment Income
                                                </button>
                                            </h2>
                                            <Collapse isOpen={col2} className="accordion-collapse">
                                                <div className="accordion-body">
                                                    If you choose any of the Offer Plans you need to invest the capital based on the plan. After investing you will start to recieve the specified percentage of amount every day/ week At one time within the duration you will receive the capital amount and the amount recieving after capital is reached will be the interest for your investment.
                                                    Eg. If you choose Elite Pack you invest Rs.50000as a capital amount. Every week you will recieve Rs.2500 which is 5% of capital amount. The total duration for this plan is 40 weeks. You will get back the invested amount in 20 weeks. The amount you receive in the remaining duration time i.e 20 weeks will be your interest. Now the interestfor this plan type is Rs.50000 (2500 * 20)
                                                </div>
                                            </Collapse>
                                        </div>

                                    </div>
                                </div>
                            </Col>

                            <Col lg={6}>
                                <div className="mt-3">
                                    <div className="d-flex align-items-center mb-2">
                                        <div className="flex-shrink-0 me-1">
                                            <i className="ri-user-settings-line fs-24 align-middle me-1" style={{"color": "#5e35b1"}}></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="fs-16 mb-0 fw-semibold">Referral Income</h5>
                                        </div>
                                    </div>

                                    <div className="accordion accordion-border-box" id="manageaccount-accordion">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="manageaccount-headingOne">
                                                <button
                                                    className={classnames(
                                                        "accordion-button",
                                                        "fw-medium",
                                                        { collapsed: !col5 }
                                                    )}
                                                    type="button"
                                                    onClick={t_col5}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    What is referral income ?
                                                </button>
                                            </h2>
                                            <Collapse isOpen={col5} className="accordion-collapse">
                                                <div className="accordion-body">
                                                    A referral income is a commission paid to an individual who brings new customers to your business. Occasionally, businesses pay referral fees in exchange for a client introduction. But more often, a referral income is tied directly to a sale.
                                                    Referral income are sometimes called finder’s fees, and they are used throughout a wide range of industries. These income are usually paid in cash.


                                                </div>
                                            </Collapse>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="manageaccount-headingTwo">
                                                <button
                                                    className={classnames(
                                                        "accordion-button",
                                                        "fw-medium",
                                                        { collapsed: !col6 }
                                                    )}
                                                    type="button"
                                                    onClick={t_col6}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    Example of Referral Income
                                                </button>
                                            </h2>
                                            <Collapse isOpen={col6} className="accordion-collapse">
                                                <div className="accordion-body">
                                                    Your referral income is 20% of capital.
                                                    Your referral amount will be credited to your bank account within 3 hrs.
                                                    Eg. If the person who invested plan of capital Rs.100000 by your referrence then you will be paid Rs.20000 which is 20% of his/ her capital.
                                                    <br/>
                                                    <div>Your referral link <input type={"text"} value={"https://wewewe"}/></div><br/>
                                                    <div>Your referral code <input type={"text"} value={"AQSSD"}/></div>
                                                </div>
                                            </Collapse>
                                        </div>

                                    </div>
                                </div>
                            </Col>


                        </Row>
                        <br/><br/>
                    </Col>
                </Row>
            </Container>
        </div>
    </React.Fragment>);

}

export default IncomeType;