import React from "react";
import {Container, Row} from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import ProfileEditRequestList from "./ProfileEditRequestList";

const ProfileEditRequest = () => {
    return (
        <React.Fragment>
            <div className="page-content">

                <Container fluid>
                    <BreadCrumb title="Profile Edit Request" pageTitle="Asha Capitals" />
                    <ProfileEditRequestList />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ProfileEditRequest;