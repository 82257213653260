import BreadCrumb from "../../Components/Common/BreadCrumb";
import React from "react";
import {Container} from "reactstrap";
import PendingPaymentList from "./PendingPaymentList";

const PendingPayments = () => {

    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Pending Payments" pageTitle="Payments" />
                    <PendingPaymentList/>
                </Container>
            </div>
        </React.Fragment>
    );
}
export default PendingPayments;