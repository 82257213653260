import {
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    GET_NOTES_LIST
} from "./actionType";

const INIT_STATE = {
    notesList: [],
};

const Notes = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case GET_NOTES_LIST:
                    return {
                        ...state,
                        notesList: action.payload,
                        isNotesCreated: false,
                        isNotesSuccess: true
                    };

                default:
                    return { ...state };
            }

        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_NOTES_LIST:
                    return {
                        ...state,
                        error: action.payload.data,
                        isNotesCreated: false,
                        isNotesSuccess: false
                    };

                default:
                    return { ...state };
            }

        case GET_NOTES_LIST: {
            return {
                ...state,
                isNotesCreated: false
            };
        }

        default:
            return { ...state };
    }
};

export default Notes;