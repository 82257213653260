import {APIClient} from "../../helpers/api_helper";
import React, { useState, useEffect } from 'react';

const UserCodeDisplay = () => {
    const api = new APIClient();
    const [referral_code, setReferralCode] = useState('');
    async function getNotesApi() {
        try{
            const res = await api.get(`/get_my_code`);
            console.log(res);
            setReferralCode(res.code);
        }catch (e){
            console.log(e);
        }
    }

    function getNotesList(){
        getNotesApi().then();
    }

    useEffect(() => {
        getNotesList();
        // if (sessionStorage.getItem("authUser")) {
        //     const obj = JSON.parse(sessionStorage.getItem("authUser"));
        //     setUserName(user.first_name || obj.data.first_name || "Admin");
        // }
    }, []);

    return (
        <React.Fragment>
            <div>Referral Code: <b>{referral_code}</b></div>
        </React.Fragment>
    );
}

export default UserCodeDisplay;