import {
    GET_NOTES_LIST,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    CREATE_NOTE,
    CREATE_NOTE_SUCCESS,
    CREATE_NOTE_ERROR,
    EDIT_NOTE,
    EDIT_NOTE_SUCCESS,
    EDIT_NOTE_ERROR,
    SHARE_NOTE,
    SHARE_NOTE_SUCCESS,
    SHARE_NOTE_ERROR,
    DELETE_NOTE,
    DELETE_NOTE_SUCCESS,
    DELETE_NOTE_ERROR
}
    from "./actionType";

export const NotesApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const NotesApiResponseError = (actionType, data) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, data },
});

export const getNotesList = () => ({
    type: GET_NOTES_LIST,
});