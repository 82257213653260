import React, {useEffect, useState} from "react";
import {APIClient} from "../../helpers/api_helper";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {Card, CardBody, CardHeader, Col, Container, Input, Label, Row} from "reactstrap";
import Select from "react-select";
import Loader from "../../Components/Common/Loader";

const PendingPaymentsView = (props) => {
    const [subscribeId, setSubscribeId] = useState(props.match.params.userId);
    const api = new APIClient();
    const [projectList, setProjectList] = useState([]);
    const [error, setError] = useState({});
    let [isProjectsSuccess, setisProjectsSuccess] = useState(true);
    const [total, setTotal] = useState(0);
    const [user, setUser] = useState({});
    const [paymode, setPaymode] = useState(2);
    const [reference_no, setReferenceNo] = useState('');
    async function getProjectsApi() {
        try{
            console.log(subscribeId);
            const res = await api.get(`/overdue-payments?user_id=${subscribeId}`);
            const res1 = await api.get(`/payment_details?&user=${subscribeId}`);
            console.log(res);
            console.log('Payment Details');
            console.log(res1);
            setUser(res1);
            setProjectList(res);
            let l = 0;
            res.forEach((r)=>{
                l = l+ r.amount;
            })
            setTotal(l);
            const fields = {};
            fields['plan_id'] = res.plan_id
            fields['type_id'] = res.type_id
            //fields['paymode'] = 2;
            fields['user_id'] = subscribeId;
            setInputFields(fields);
            console.log(inputFields);
            setisProjectsSuccess(true);
        }catch (e){
            console.log(e);
            setError('Invalid Request');
            setisProjectsSuccess(false);
        }
    }

    function getProjectsList(){
        getProjectsApi().then();
    }
    useEffect(() => {
        console.log('page');
        getProjectsList();
    }, []);

    const SingleOptions = [
        { value: 'Watches', label: 'Watches' },
        { value: 'Headset', label: 'Headset' },
        { value: 'Sweatshirt', label: 'Sweatshirt' },
        { value: '20% off', label: '20% off' },
        { value: '4 star', label: '4 star' },
    ];

    const [selectedMulti, setselectedMulti] = useState(null);

    const handleMulti = (selectedMulti) => {
        setselectedMulti(selectedMulti);
    }

    //Dropzone file upload
    const [selectedFiles, setselectedFiles] = useState([]);
    const [files, setFiles] = useState([]);

    const [inputFields, setInputFields] = useState({});
    const [attachments, setAttachments] = useState();

    const handleInputChange = (event) => {
        inputFields[event.target.name] = event.target.value;
        //inputFields['full_name'] = inputFields['first_name'] + ' ' + inputFields['last_name'];
        setInputFields(inputFields);
        console.log(inputFields);
    }

    const handleFileChange = (event) =>{
        setAttachments(event.target.files[0]);
    }

    async function postApi(data) {
        var formData = new FormData();
        inputFields['paymode'] = paymode;
        inputFields['reference_no'] = reference_no;
        formData.append("data", JSON.stringify(inputFields));
        console.log(attachments);
        formData.append('file', attachments);
        try{
            const response = await api.create(`/make-payments`, formData);
            console.log(response);
            return response;
        }catch (e){
            console.log(e);
        }
    }

    function postData(data){
        postApi(data).then((response) => {
            props.history.push("/pending-payments");

        });
    }

    const handleSubmit = (event) =>{
        var data = new FormData();
        data.append("data", JSON.stringify(inputFields));
        data.append('file', attachments);
        postData(data);

    }

    const handleCancel = (event) => {
        props.history.push("/pending-payments");
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Pending Payments" pageTitle="Payments" />
                    <form id="myForm" method="post" action={'#'} encType="multipart/form-data" onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                        return false;
                    }}>
                        <Row>
                            <Card>
                                <CardBody>
                                    <div className="table-responsive">
                                    <table className="align-middle table-nowrap mb-0 table">
                                        <thead>
                                        <tr>
                                            <th scope="col">Plan Name</th>
                                            <th scope="col">Plan Type</th>
                                            <th scope="col">Plan ID</th>
                                            <th scope="col">Due Count</th>
                                            <th scope="col">Due Date</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">User</th>

                                        </tr>
                                        </thead>
                                        <tbody>
                                        {isProjectsSuccess  ? (
                                            (projectList || []).map((item, index) => (
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        <td>{item.plan_name}</td>
                                                        <td>{item.plan_type_name}</td>
                                                        <td>{item.plan_id}</td>
                                                        <td>{(new Date(item.due_date)).toLocaleDateString()}</td>
                                                        <td>{item.due_count}</td>
                                                        <td>{item.amount}</td>
                                                        <td>{item.user_name}</td>
                                                    </tr>
                                                </React.Fragment>
                                            ))

                                        ) : (<Loader error={error} />)
                                        }
                                        </tbody>
                                    </table>
                                        <div style={{'float':'right','padding-top': "10px"}}>
                                            <b>Total : {total}</b>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>

                        </Row>
                        <Row>
                            <Col lg={12}>

                                <Row>
                                    <Card>
                                        <CardHeader>
                                            <h5 className="card-title mb-0">User Bank Details</h5>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">User Bank Name</Label>
                                                        <Input type="text" className="form-control" id="project-title-input"
                                                               name={'email'} value={user.bank_name}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">User IFSC Code</Label>
                                                        <Input type="text" className="form-control" name={'mobile'} value={user.ifsc}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">User Account Name</Label>
                                                        <Input type="text" className="form-control" name={'mobile'} value={user.account_name}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">User Account Number</Label>
                                                        <Input type="text" className="form-control" name={'mobile'} value={user.account_no}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Row>
                                <Row>
                                    <Card>
                                        <CardHeader>
                                            <h5 className="card-title mb-0">User UPI Details</h5>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">User UPI ID</Label>
                                                        <Input type="text" className="form-control" id="project-title-input"
                                                               name={'email'} value={user.upi_id}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Row>
                                <Row>
                                    <Card>
                                        <CardHeader>
                                            <h5 className="card-title mb-0">Pay Details</h5>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Payment Mode</Label>
                                                        <select value={paymode} name={'paymode'} className="form-select" data-choices data-choices-search-false onChange={event => setPaymode(event.target.value)}>
                                                            <option value={2}>Bank</option>
                                                            <option value={1}>UPI</option>
                                                        </select>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Reference Number</Label>
                                                        <Input type="text" className="form-control" name={'reference_no'} value={reference_no}
                                                               onChange={event => setReferenceNo(event.target.value)}
                                                               placeholder="Enter Reference No" />
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-thumbnail-img">Reference Screenshot</Label>
                                                        <Input className="form-control" name={'file'} value={inputFields.file}
                                                               onChange={event => handleFileChange(event)} type="file" accept="image/png, image/gif, image/jpeg" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="mb-4">
                                            <button onClick={event => handleCancel(event)} type="button" className="btn btn-danger w-sm me-1">Cancel</button>
                                            <button type="submit" className="btn btn-success w-sm me-1">Submit</button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </form>
                </Container>
            </div>
        </React.Fragment>
    );

}

export default PendingPaymentsView;