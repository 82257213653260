import React from "react";
import {Container, Row} from "reactstrap";

import EmployeeList from "./EmployeeList";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

const EmployeeListView = () => {
    return (
        <React.Fragment>
            <div className="page-content">

                <Container fluid>
                    <BreadCrumb title="Employee List" pageTitle="Asha Capitals" />
                    <EmployeeList />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default EmployeeListView;