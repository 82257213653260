export const GET_NOTES_LIST = "GET_NOTES_LIST";
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";

export const CREATE_NOTE = "CREATE_NOTE";
export const CREATE_NOTE_SUCCESS = "CREATE_NOTE_SUCCESS";
export const CREATE_NOTE_ERROR = "CREATE_NOTE_ERROR";

export const EDIT_NOTE = "EDIT_NOTE";
export const EDIT_NOTE_SUCCESS = "EDIT_NOTE_SUCCESS";
export const EDIT_NOTE_ERROR = "EDIT_NOTE_ERROR";

export const SHARE_NOTE = "SHARE_NOTE";
export const SHARE_NOTE_SUCCESS = "SHARE_NOTE_SUCCESS";
export const SHARE_NOTE_ERROR = "SHARE_NOTE_ERROR";

export const DELETE_NOTE = "DELETE_NOTE";
export const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS";
export const DELETE_NOTE_ERROR = "DELETE_NOTE_ERROR";