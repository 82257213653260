import React, {useEffect, useState} from "react";
import {APIClient} from "../../helpers/api_helper";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {Card, CardBody, CardHeader, Col, Container, Input, Label, Row} from "reactstrap";
import Select from "react-select";
import upi from "../../assets/images/asha_upi.jpeg";

const SubscribeView = (props) => {
    const [subscribeId, setSubscribeId] = useState(props.match.params.subscribeId);
    const api = new APIClient();
    const [projectList, setProjectList] = useState({});
    const [error, setError] = useState({});
    let [isProjectsSuccess, setisProjectsSuccess] = useState(true);
    const [reference_no, setReferenceNo] = useState('');
    const [paymode, setPaymode] = useState('bank');
    async function getProjectsApi() {
        try{
            console.log(subscribeId);
            const res = await api.get(`/get-plan?plan_id=${subscribeId}`);
            console.log(res);
            setProjectList(res);
            const fields = {};
            fields['plan_id'] = res.plan_id
            fields['type_id'] = res.type_id
            fields['paymode'] = 'bank';
            setPaymode('bank');
            setInputFields(fields);
            setisProjectsSuccess(true);
        }catch (e){
            console.log(e);
            setError('Invalid Request');
            setisProjectsSuccess(false);
        }
    }

    function getProjectsList(){
        getProjectsApi().then();
    }
    useEffect(() => {
        console.log('page');
        getProjectsList();
    }, []);

    const SingleOptions = [
        { value: 'Watches', label: 'Watches' },
        { value: 'Headset', label: 'Headset' },
        { value: 'Sweatshirt', label: 'Sweatshirt' },
        { value: '20% off', label: '20% off' },
        { value: '4 star', label: '4 star' },
    ];

    const [selectedMulti, setselectedMulti] = useState(null);

    const handleMulti = (selectedMulti) => {
        setselectedMulti(selectedMulti);
    }

    //Dropzone file upload
    const [selectedFiles, setselectedFiles] = useState([]);
    const [files, setFiles] = useState([]);

    const [inputFields, setInputFields] = useState({});
    const [attachments, setAttachments] = useState();

    const handleInputChange = (event) => {
        inputFields[event.target.name] = event.target.value;
        //inputFields['full_name'] = inputFields['first_name'] + ' ' + inputFields['last_name'];
        setInputFields(inputFields);
        console.log(inputFields);
    }

    const handleFileChange = (event) =>{
        setAttachments(event.target.files[0]);
    }

    async function postApi() {
        var formData = new FormData();
        inputFields['reference_no'] = reference_no;
        inputFields['paymode'] = paymode;
        formData.append("data", JSON.stringify(inputFields));
        console.log(attachments);
        formData.append('file', attachments);
        try{
            const response = await api.create(`/new-subscribe`, formData);
            console.log(response);
            return response;
        }catch (e){
            console.log(e);
        }
    }

    function postData(){
        postApi().then((response) => {
            props.history.push("/offer-plans");

        });
    }

    const handleSubmit = (event) =>{
        if(typeof attachments === "undefined"){
            alert('You need to upload reference attachments');
            return false;
        }
        if(reference_no === ''){
            alert('Reference No should not be empty');
            return false;
        }
        postData();

    }

    const handleCancel = (event) => {
        props.history.push("/offer-plans");
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Subscribe Plan" pageTitle="Subscribe" />
                    <form id="myForm" method="post" action={'#'} encType="multipart/form-data" onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                        return false;
                    }}>
                        <Row>
                            <Col lg={12}>
                                <Row>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Plan Name</Label>
                                                        <Input readOnly={true} type="text" className="form-control" id="project-title-input"
                                                               name={'plan_name'} value={projectList.plan_name} />
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Plan Type</Label>
                                                        <Input readOnly={true} type="text" className="form-control" id="project-title-input"
                                                               name={'type_name'} value={projectList.type_name}/>
                                                    </div>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Description</Label>
                                                        <Input readOnly={true} type="text" className="form-control" id="project-title-input"
                                                               name={'description'} value={projectList.description}
                                                               />
                                                    </div>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Amount</Label>
                                                        <Input readOnly={true} type="text" className="form-control" id="project-title-input"
                                                               name={'amount'} value={projectList.amount}
                                                               />
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">EMI Amount</Label>
                                                        <Input readOnly={true} type="text" className="form-control" id="project-title-input"
                                                               name={'amount'} value={projectList.emi_amount}
                                                               />
                                                    </div>
                                                </Col>

                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Row>
                                <Row>
                                    <Card>
                                        <CardHeader>
                                            <h5 className="card-title mb-0">Pay To Bank Details</h5>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Pay To Bank Name</Label>
                                                        <Input type="text" className="form-control" id="project-title-input"
                                                               name={'email'} value={projectList.bank_name}
                                                               />
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Pay To IFSC Code</Label>
                                                        <Input type="text" className="form-control" name={'mobile'} value={projectList.ifsc}
                                                               />
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Pay To Account Name</Label>
                                                        <Input type="text" className="form-control" name={'mobile'} value={projectList.account_name}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Pay To Account Number</Label>
                                                        <Input type="text" className="form-control" name={'mobile'} value={projectList.account_no}
                                                               />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Row>
                                <Row>
                                    <Card>
                                        <CardHeader>
                                            <h5 className="card-title mb-0">Pay To UPI</h5>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Pay To UPI ID</Label>
                                                        <Input type="text" className="form-control" id="project-title-input"
                                                               name={'email'} value={projectList.upi_id}
                                                               />
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <img height={'200px'} src={upi}/>
                                                </Col>
                                            </Row>
                                            <Row>

                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Row>
                                <Row>
                                    <Card>
                                        <CardHeader>
                                            <h5 className="card-title mb-0">Pay Details</h5>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Payment Mode</Label>
                                                        <select value={paymode} name={'paymode'} className="form-select" data-choices data-choices-search-false onChange={event => setPaymode(event.target.value)}>
                                                            <option value={'bank'}>Bank</option>
                                                            <option value={'upi'}>UPI</option>
                                                        </select>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Reference Number</Label>
                                                        <Input type="text" className="form-control" name={'reference_no'} value={reference_no}
                                                               onChange={event => setReferenceNo(event.target.value)}
                                                               placeholder="Enter Reference No" />
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-thumbnail-img">Reference Screenshot</Label>
                                                        <Input className="form-control" name={'file'} value={inputFields.file}
                                                               onChange={event => handleFileChange(event)} type="file" accept="image/png, image/gif, image/jpeg" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="mb-4">
                                            <button onClick={event => handleCancel(event)} type="button" className="btn btn-danger w-sm me-1">Cancel</button>
                                            <button type="submit" className="btn btn-success w-sm me-1">Submit</button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            {/*<Col lg={4}>*/}
                            {/*    <Card>*/}
                            {/*        <CardHeader>*/}
                            {/*            <h5 className="card-title mb-0">Address</h5>*/}
                            {/*        </CardHeader>*/}
                            {/*        <CardBody>*/}
                            {/*            <div className="mb-3">*/}
                            {/*                <Label className="form-label" htmlFor="project-title-input">Street</Label>*/}
                            {/*                <Input type="text" className="form-control" id="project-title-input"*/}
                            {/*                       placeholder="Enter Street" />*/}
                            {/*            </div>*/}
                            {/*            <div className="mb-3">*/}
                            {/*                <Label className="form-label" htmlFor="project-title-input">City</Label>*/}
                            {/*                <Input type="text" className="form-control" id="project-title-input"*/}
                            {/*                       placeholder="Enter City" />*/}
                            {/*            </div>*/}
                            {/*            <div className="mb-3">*/}
                            {/*                <Label className="form-label" htmlFor="project-title-input">State</Label>*/}
                            {/*                <Input type="text" className="form-control" id="project-title-input"*/}
                            {/*                       placeholder="Enter State" />*/}
                            {/*            </div>*/}
                            {/*            <div className="mb-3">*/}
                            {/*                <Label className="form-label" htmlFor="project-title-input">Country</Label>*/}
                            {/*                <Input type="text" className="form-control" id="project-title-input"*/}
                            {/*                       placeholder="Enter Country" />*/}
                            {/*            </div>*/}
                            {/*            <div className="mb-3">*/}
                            {/*                <Label className="form-label" htmlFor="project-title-input">Zip Code</Label>*/}
                            {/*                <Input type="text" className="form-control" id="project-title-input"*/}
                            {/*                       placeholder="Enter Zip Code" />*/}
                            {/*            </div>*/}


                            {/*        </CardBody>*/}
                            {/*    </Card>*/}
                            {/*    <Card>*/}
                            {/*        <CardHeader>*/}
                            {/*            <h5 className="card-title mb-0">Status</h5>*/}
                            {/*        </CardHeader>*/}
                            {/*        <CardBody>*/}
                            {/*            <div className="mb-3">*/}
                            {/*                <Label htmlFor="choices-categories-input" className="form-label">Lead Source</Label>*/}
                            {/*                <select className="form-select" data-choices data-choices-search-false*/}
                            {/*                        id="choices-categories-input">*/}
                            {/*                    <option defaultValue="Designing">Designing</option>*/}
                            {/*                    <option value="Development">Development</option>*/}
                            {/*                </select>*/}
                            {/*            </div>*/}

                            {/*            <div>*/}
                            {/*                <Label htmlFor="choices-text-input" className="form-label">Lead Status</Label>*/}
                            {/*                <Select*/}
                            {/*                    value={selectedMulti}*/}
                            {/*                    isMulti={true}*/}
                            {/*                    onChange={() => {*/}
                            {/*                        handleMulti();*/}
                            {/*                    }}*/}
                            {/*                    options={SingleOptions}*/}
                            {/*                />*/}
                            {/*            </div>*/}


                            {/*        </CardBody>*/}
                            {/*    </Card>*/}

                            {/*</Col>*/}
                        </Row>
                    </form>
                </Container>
            </div>
        </React.Fragment>
    );

}

export default SubscribeView;