import React from "react";
import {Container, Row} from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import SuggestionList from "./SuggestionList";

const SuggestionsListView = () => {
    return (
        <React.Fragment>
            <div className="page-content">

                <Container fluid>
                    <BreadCrumb title="Suggestions" pageTitle="Asha Capitals" />
                    <SuggestionList />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default SuggestionsListView;