import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
//Import Flatepicker
import Select from "react-select";
import {APIClient} from "../../../../connector/client";

const EmployeeAddForm = (props) => {
    const api = new APIClient();

    const[first_name, setFirstName] = useState('');
    const[last_name, setLastName] = useState('');
    const[email, setEmail] = useState('');
    const[mobile, setMobile] = useState('');
    const[role, setRole] = useState('2');



    const [inputFields, setInputFields] = useState({'password':'1234'});
    const [attachments, setAttachments] = useState();

    const handleInputChange = (event) => {
        inputFields[event.target.name] = event.target.value;
        //inputFields['full_name'] = inputFields['first_name'] + ' ' + inputFields['last_name'];
        setInputFields(inputFields);
        console.log(inputFields);
    }


    const handleCancel = (event) => {
        props.history.push("/users");
    }

    async function postApi() {
        var data = {'first_name': first_name, 'last_name': last_name, 'email':email, 'mobile':mobile, 'password':'1234',
        'role':role}
        try{
            const response = await api.create(`/create_employee`, data);
            console.log(response);
            return response;
        }catch (e){
            console.log(e);
        }
    }

    function postData(data){
        postApi(data).then((response) => {
            props.history.push("/users");

        });
    }

    const handleSubmit = (event) =>{
        var data = new FormData();
        data.append("data", JSON.stringify(inputFields));
        data.append('file', attachments);
        postData();

    }



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Create Employee" pageTitle="Users" />
                    <form id="myForm" method="post" action={'#'} encType="multipart/form-data" onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                        return false;
                    }}>
                        <Row>
                            <Col lg={8}>
                                <Row>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">First Name</Label>
                                                        <Input type="text" className="form-control" id="project-title-input"
                                                               placeholder="Enter First Name" name={'first_name'} value={first_name}
                                                               onChange={event => setFirstName(event.target.value)} />
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Last Name</Label>
                                                        <Input type="text" className="form-control" id="project-title-input"
                                                               placeholder="Enter Last Name" name={'last_name'} value={last_name}
                                                               onChange={event => setLastName(event.target.value)}/>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Row>
                                <Row>
                                    <Card>
                                        <CardHeader>
                                            <h5 className="card-title mb-0">Employee Information</h5>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">E-Mail</Label>
                                                        <Input type="text" className="form-control" id="project-title-input"
                                                               placeholder="Enter E-Mail" name={'email'} value={email}
                                                               onChange={event => setEmail(event.target.value)}/>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Mobile</Label>
                                                        <Input type="text" className="form-control" name={'mobile'} value={mobile}
                                                               onChange={event => setMobile(event.target.value)}
                                                               placeholder="Enter Mobile" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Role</Label>
                                                        <select value={role} name={'role'} className="form-select" data-choices data-choices-search-false onChange={event => setRole(event.target.value)}>
                                                            <option value={2}>Referral Payment</option>
                                                            <option value={3}>Client Payment</option>
                                                            <option value={4}>Plan Approval</option>
                                                            <option value={5}>Profile Edit Support</option>
                                                            {/*<option value={6}>Special Offer & Income Type</option>*/}
                                                            {/*<option value={7}>Pending Payment</option>*/}
                                                            {/*<option value={8}>Suggestion & Complaint / Review & Feedback</option>*/}
                                                            {/*<option value={9}>Support</option>*/}
                                                        </select>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="mb-4">
                                            <button onClick={event => handleCancel(event)} type="button" className="btn btn-danger w-sm me-1">Cancel</button>
                                            <button type="submit" className="btn btn-success w-sm me-1">Submit</button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    </form>


                </Container>
            </div>
        </React.Fragment>
    );
};

export default EmployeeAddForm;