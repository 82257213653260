import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap';
//Import Flatepicker
import Select from "react-select";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {APIClient} from "../../connector/client";

const SpecialOffers = (props) => {
    const api = new APIClient();
    const [target, setTarget] = useState('');
    const [from_date,setFromDate] = useState('');
    const[to_date, setToDate] = useState('');
    const[cash_reward, setCashReward] = useState('');
    const[gold_reward, setGoldReward] = useState('');
    const [trip_reward, setTripReward] = useState('');

    const handleCancel = (event) => {
        props.history.push("/special-offers");
    }

    async function getNotesApi() {
        try{
            const res = await api.get(`/offers`);
            console.log(res);
            setTarget(res.target);
            setFromDate(res.from_date);
            setToDate(res.to_date);
            setCashReward(res.cash_reward);
            setGoldReward(res.gold_reward);
            setTripReward(res.trip_reward);
        }catch (e){
            console.log(e);

        }
    }

    function getNotesList(){
        getNotesApi().then();
    }

    useEffect(() => {
        getNotesList();
    }, []);

    async function postApi() {
        var data_obj = {};
        data_obj['target'] = target;
        data_obj['from_date'] = from_date;
        data_obj['to_date'] = to_date;
        data_obj['cash_reward'] = cash_reward;
        data_obj['gold_reward'] = gold_reward;
        data_obj['trip_reward'] = trip_reward;
        if(from_date > to_date){
            alert('Invalid Date');
            return false;
        }
        if(target.length <=0){
            alert('Invalid Target Value');
            return false;
        }

        try{
            const response = await api.create(`/offers`, data_obj);
            console.log(response);
            return response;
        }catch (e){
            console.log(e);
        }
    }

    function postData(){
        postApi().then((response) => {
            props.history.push("/special-offers");

        });
    }

    const handleSubmit = (event) =>{
        postData();

    }



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Special Offers" pageTitle="Offers" />
                    <form id="myForm" method="post" action={'#'} encType="multipart/form-data" onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                        return false;
                    }}>
                        <Row>
                            <Col lg={8}>
                                <Row>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Target</Label>
                                                        <Input type="number" className="form-control" id="project-title-input"
                                                               placeholder="Enter Target" name={'target'} value={target}
                                                               onChange={event => setTarget(event.target.value)} />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">From Date</Label>
                                                        <Input type="date" className="form-control" id="project-title-input"
                                                               placeholder="Enter From Date" name={'date'} value={from_date}
                                                               onChange={event => setFromDate(event.target.value)}/>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">To Date</Label>
                                                        <Input type="date" className="form-control" id="project-title-input"
                                                               placeholder="Enter To Date" name={'date'} value={to_date}
                                                               onChange={event => setToDate(event.target.value)}/>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Cash Reward</Label>
                                                        <Input type="number" className="form-control" id="project-title-input"
                                                               placeholder="Enter Cash Reward" name={'cash_reward'} value={cash_reward}
                                                               onChange={event => setCashReward(event.target.value)}/>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Gold Reward</Label>
                                                        <Input type="number" className="form-control" id="project-title-input"
                                                               placeholder="Enter Gold Reward" name={'gold_reward'} value={gold_reward}
                                                               onChange={event => setGoldReward(event.target.value)}/>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Trip Reward</Label>
                                                        <Input type="text" className="form-control" id="project-title-input"
                                                               placeholder="Enter Trip Reward" name={'trip_reward'} value={trip_reward}
                                                               onChange={event => setTripReward(event.target.value)}/>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Row>

                                <Row>
                                    <Col>
                                        <div className="mb-4">
                                            <button onClick={event => handleCancel(event)} type="button" className="btn btn-danger w-sm me-1">Cancel</button>
                                            <button type="submit" className="btn btn-success w-sm me-1">Submit</button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    </form>


                </Container>
            </div>
        </React.Fragment>
    );
};

export default SpecialOffers;