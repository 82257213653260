import axios from "axios";
import { api } from "../config";

// default
axios.defaults.baseURL = api.API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

const get_token = (name)=>{
  //var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  //return (match) ? match[2] :  null;
  return localStorage.getItem('token');
}
//const token = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).token : null;
if(get_token("token"))
  axios.defaults.headers.common["Authorization"] = "Token " + get_token("token");
// content type
//const token = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).token : null;
//if(token)
//axios.defaults.headers.common["Authorization"] = "Token " + token;

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject(message);
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = "Token " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  get = (url, params) => {
    let response;

    let paramKeys = [];

    if (params) {
      Object.keys(params).map(key => {
        paramKeys.push(key + '=' + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
      try{
        response = axios.get(`${url}?${queryString}`, params);
      }catch (e){
        console.log(e);
      }

    } else {
      try{
        response = axios.get(`${url}`, params);
      }catch (e){
        console.log(e);
      }

    }

    return response;
  };
  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.patch(url, data);
  };

  put = (url, data) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}
const getCookie = (cname) =>{
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}
const getLoggedinUser = () => {
  //const token = getCookie('token');
  const token = localStorage.getItem("token");
  if(!token){
    return null;
  }
  const user = localStorage.getItem("user");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedinUser , get_token};