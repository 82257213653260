import React, {useEffect, useState} from 'react';
import {
    Card,
    CardBody, CardFooter,
    CardHeader,
    Col, Collapse,
    Container,
    Form, Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from 'reactstrap';
import classnames from "classnames";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import ProfileDetails from "./ProfileDetails";
import ProfilePlans from "./ProfilePlans";
import ProfilePayment from "./ProfilePayment";
import {APIClient} from "../../helpers/api_helper";
import {Link} from "react-router-dom";
import Loader from "../../Components/Common/Loader";


const ProfileView = (props) => {
    const api = new APIClient();
    let [isProjectsSuccess, setisProjectsSuccess] = useState(true);
    const [error, setError] = useState({});
    const [activeArrowTab, setactiveArrowTab] = useState(4);
    const [passedSteps, setPassedSteps] = useState([1]);
    const [passedarrowSteps, setPassedarrowSteps] = useState([1]);
    const [userInfo, setUserInfo] = useState({});
    const [projectList, setProjectList] = useState([]);
    const [paymentInfo, setPaymentInfo] = useState({});
    const [planID, setPlanID] = useState(null);

    const [inputFields, setInputFields] = useState({});
    const [canEdit, setCanEdit] = useState(false);

    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [district, setDistrict] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [pincode, setPinCode] = useState('');
    const [re_bank_name, setReBankName] = useState('');
    const [re_ifsc, setReIFSC] = useState('');
    const [re_account_name, setReAccountName] = useState('');
    const [re_account_no, setReAccountNo] = useState('');
    const [re_upi_id, setReUPIID] = useState('');
    const [bank_name, setBankName] = useState('');
    const [ifsc, setIFSC] = useState('');
    const [account_name, setAccountName] = useState('');
    const [account_no, setAccountNo] = useState('');
    const [upi_id, setUPIID] = useState('');
    const [nomine_name, setNomineName] = useState('');
    const [nomine_contact, setNomineContact] = useState('');
    const [relationship, setRelationship] = useState('');
    const [editRequest, setEditRequest] = useState(false);

    const handleInputChange = (event) => {
        inputFields[event.target.name] = event.target.value;
        setInputFields(inputFields);
        console.log(inputFields);
    }

    async function getProjectsApi() {
        try{
            const res1 = await api.get(`/get_current_user_info`);
            console.log(res1);
            setUserInfo(res1);
            setFirstName(res1.first_name);
            setLastName(res1.last_name);
            setAddress(res1.address);
            setDistrict(res1.district);
            setState(res1.state);
            setCountry(res1.country);
            setPinCode(res1.pincode);
            setBankName(res1.bank_name);
            setReBankName(res1.bank_name);
            setIFSC(res1.ifsc);
            setReIFSC(res1.ifsc);
            setAccountName(res1.account_name);
            setReAccountName(res1.account_name);
            setAccountNo(res1.account_no);
            setReAccountNo(res1.account_no);
            setCanEdit(res1.can_edit);
            setUPIID(res1.upi_id);
            setReUPIID(res1.upi_id);
            setNomineName(res1.nomine_name);
            setNomineContact(res1.nomine_contact);
            setRelationship(res1.relationship);
            setEditRequest(res1.edit_requested);
            setInputFields(res1);
            setisProjectsSuccess(true);
        }catch (e){
            console.log(e);
            setError('Invalid Request');
            setisProjectsSuccess(false);
        }
    }

    function getProjectsList(){
        getProjectsApi().then();
    }

    useEffect(() => {
        getProjectsList();
    }, []);

    async function updateUserApi() {
        try {
            const data = {'first_name': first_name, 'last_name': last_name, 'address': address,'district': district,'state': state,
            'country':country,'pincode':pincode,'bank_name':bank_name, 'ifsc':ifsc,'account_name': account_name,'account_no':account_no,
            'upi_id':upi_id,'nomine_name': nomine_name, 'nomine_contact':nomine_contact, 'relationship':relationship}
            const res = await api.create('/update_user', data);
            return res
        } catch (e) {
            console.log(e);
        }

    }

    function updateUser(){
        updateUserApi().then((res)=>{
            alert('Profile Updated Successfully');
            props.history.push("/offer-plans");
        });
    }
    function toggleArrowTab(tab) {
        if(pincode.length !== 6){
            alert('Invalid Pin Code');
            return false;
        }
        if(!upi_id.includes('@')){
            alert('Invalid UPI ID');
            return false;
        }
            if(bank_name !== re_bank_name){
                alert('Incorrect Bank Name');
                return false;
            }
            if(account_name !== re_account_name){
                alert('Incorrect Account Name');
                return false;
            }
            if(account_no !== re_account_no){
                alert('Incorrect Account Number');
                return false;
            }
            if(ifsc !== re_ifsc){
                alert('Incorrect IFSC Code');
                return false;
            }
            if(upi_id !== re_upi_id){
                alert('Incorrect UPI ID');
                return false;
            }
            updateUser();

    }


    const handleSubmit = (event) =>{

    }

    async function requestEditApi() {
        try {
            const res = await api.get('/request-edit');
            return res
        } catch (e) {
            console.log(e);
        }
    }

    function requestEdit(){
        requestEditApi().then((res)=>{
            alert('Request Raised Successfully');
        });
    }



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Profile" pageTitle="Asha Capitals" />
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col>
                                            <h4 className="card-title mb-0">Profile</h4>
                                        </Col>
                                        <Col>
                                            {canEdit ? (<>
                                            </>) : (<>
                                                {editRequest ? (<>
                                                    You already requested to Edit. Kindly Wait
                                                </>) : (<>
                                                    <button onClick={requestEdit} style={{float:"right"}} className="btn btn-success ms-auto nexttab nexttab">Request to Edit</button>
                                                </>)}

                                            </>)}

                                        </Col>
                                    </Row>

                                </CardHeader>
                                <CardBody className="form-steps">
    <Form id="myForm" method="post" action={'#'} onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(e);
        return false;
    }}>
                                                <React.Fragment>
                                                    <div>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="steparrow-gen-info-email-input"
                                                                    >
                                                                        First Name
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="steparrow-gen-info-email-input"
                                                                        placeholder="Enter First Name" name={'first_name'} value={first_name}
                                                                        onChange={event => setFirstName(event.target.value)}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="steparrow-gen-info-username-input"
                                                                    >
                                                                        Last Name
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="steparrow-gen-info-username-input"
                                                                        placeholder="Enter Last Name" name={'last_name'} value={last_name}
                                                                        onChange={event => setLastName(event.target.value)}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="steparrow-gen-info-email-input"
                                                                    >
                                                                        Address
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="steparrow-gen-info-email-input"
                                                                        placeholder="Enter Address" name={'address'} value={address}
                                                                        onChange={event => setAddress(event.target.value)}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="steparrow-gen-info-email-input"
                                                                    >
                                                                        District
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="steparrow-gen-info-email-input"
                                                                        placeholder="Enter District" name={'district'} value={district}
                                                                        onChange={event => setDistrict(event.target.value)}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="steparrow-gen-info-username-input"
                                                                    >
                                                                        State
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="steparrow-gen-info-username-input"
                                                                        placeholder="Enter State" name={'state'} value={state}
                                                                        onChange={event => setState(event.target.value)}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="steparrow-gen-info-email-input"
                                                                    >
                                                                        Country
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="steparrow-gen-info-email-input"
                                                                        placeholder="Enter Country" name={'country'} value={country}
                                                                        onChange={event => setCountry(event.target.value)}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="steparrow-gen-info-username-input"
                                                                    >
                                                                        Pin Code
                                                                    </Label>
                                                                    <Input
                                                                        type="number"
                                                                        className="form-control"
                                                                        id="steparrow-gen-info-username-input"
                                                                        placeholder="Enter Pin Code" name={'pincode'} value={pincode}
                                                                        onChange={event => setPinCode(event.target.value)}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="steparrow-gen-info-email-input"
                                                                    >
                                                                        Bank Name
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="steparrow-gen-info-email-input"
                                                                        placeholder="Enter Bank Name" name={'bank_name'} value={bank_name}
                                                                        onChange={event => setBankName(event.target.value)}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="steparrow-gen-info-email-input"
                                                                    >
                                                                        Re-Enter Bank Name
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="steparrow-gen-info-email-input"
                                                                        placeholder="Re-Enter Bank Name" name={'re_bank_name'} value={re_bank_name}
                                                                        onChange={event => setReBankName(event.target.value)}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="steparrow-gen-info-username-input"
                                                                    >
                                                                        IFSC Code
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="steparrow-gen-info-username-input"
                                                                        placeholder="Enter IFSC Code" name={'ifsc'} value={ifsc}
                                                                        onChange={event => setIFSC(event.target.value)}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="steparrow-gen-info-username-input"
                                                                    >
                                                                        Re-Enter IFSC Code
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="steparrow-gen-info-username-input"
                                                                        placeholder="Re-Enter IFSC Code" name={'re_ifsc'} value={re_ifsc}
                                                                        onChange={event => setReIFSC(event.target.value)}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="steparrow-gen-info-email-input"
                                                                    >
                                                                        Account Name
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="steparrow-gen-info-email-input"
                                                                        placeholder="Enter Account Name" name={'account_name'} value={account_name}
                                                                        onChange={event => setAccountName(event.target.value)}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="steparrow-gen-info-email-input"
                                                                    >
                                                                        Re-Enter Account Name
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="steparrow-gen-info-email-input"
                                                                        placeholder="Re-Enter Account Name" name={'re_account_name'} value={re_account_name}
                                                                        onChange={event => setReAccountName(event.target.value)}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row><Row>
                                                            <Col>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="steparrow-gen-info-email-input"
                                                                    >
                                                                        Account No
                                                                    </Label>
                                                                    <Input
                                                                        type="number"
                                                                        className="form-control"
                                                                        id="steparrow-gen-info-email-input"
                                                                        placeholder="Enter Account No" name={'account_no'} value={account_no}
                                                                        onChange={event => setAccountNo(event.target.value)}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        <Col>
                                                            <div className="mb-3">
                                                                <Label
                                                                    className="form-label"
                                                                    htmlFor="steparrow-gen-info-email-input"
                                                                >
                                                                    Re-Enter Account No
                                                                </Label>
                                                                <Input
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="steparrow-gen-info-email-input"
                                                                    placeholder="Re-Enter Account No" name={'re_account_no'} value={re_account_no}
                                                                    onChange={event => setReAccountNo(event.target.value)}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row><Row>
                                                            <Col>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="steparrow-gen-info-username-input"
                                                                    >
                                                                        UPI ID
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="steparrow-gen-info-username-input"
                                                                        placeholder="Enter UPI ID" name={'upi_id'} value={upi_id}
                                                                        onChange={event => setUPIID(event.target.value)}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        <Col>
                                                            <div className="mb-3">
                                                                <Label
                                                                    className="form-label"
                                                                    htmlFor="steparrow-gen-info-username-input"
                                                                >
                                                                    Re-Enter UPI ID
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="steparrow-gen-info-username-input"
                                                                    placeholder="Re-Enter UPI ID" name={'re_upi_id'} value={re_upi_id}
                                                                    onChange={event => setReUPIID(event.target.value)}
                                                                />
                                                            </div>
                                                        </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="steparrow-gen-info-email-input"
                                                                    >
                                                                        Nominee Name
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="steparrow-gen-info-email-input"
                                                                        placeholder="Enter Nominee Name" name={'nomine_name'} value={nomine_name}
                                                                        onChange={event => setNomineName(event.target.value)}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="steparrow-gen-info-email-input"
                                                                    >
                                                                        Nominee Contact
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="steparrow-gen-info-email-input"
                                                                        placeholder="Enter Nominee Contact" name={'nomine_contact'} value={nomine_contact}
                                                                        onChange={event => setNomineContact(event.target.value)}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row><Row>
                                                            <Col lg={6}>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="steparrow-gen-info-email-input"
                                                                    >
                                                                        Relationship
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="steparrow-gen-info-email-input"
                                                                        placeholder="Enter Relationship" name={'relationship'} value={relationship}
                                                                        onChange={event => setRelationship(event.target.value)}
                                                                    />
                                                                </div>
                                                            </Col>

                                                        </Row>
                                                    </div>

                                                </React.Fragment>
                                                <div className="d-flex align-items-start gap-3 mt-4">
                                                    {canEdit ? (<>
                                                        <button
                                                            type="button"
                                                            className="btn btn-success ms-auto nexttab nexttab"
                                                            onClick={() => {
                                                                toggleArrowTab(activeArrowTab + 1);
                                                            }}
                                                        >
                                                            Submit
                                                        </button>
                                                    </>) : (<>
                                                    </>)}

                                                </div>
    </Form>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ProfileView;