import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {NotesApiResponseError, NotesApiResponseSuccess} from "./action";
import {getNotesList as getNotesListApi} from "./api";
import {GET_NOTES_LIST} from "./actionType";

function* getNotesList() {
    try {
        const response = yield call(getNotesListApi);
        yield put(NotesApiResponseSuccess(GET_NOTES_LIST, response.data));
    } catch (error) {
        yield put(NotesApiResponseError(GET_NOTES_LIST, error));
    }
}

export function* watchGetNotesList() {
    yield takeEvery(GET_NOTES_LIST, getNotesList);
}

function* notesSaga() {
    yield all([
            fork(watchGetNotesList)
        ]
    );
}

export default notesSaga;