import {Card, Col, Collapse, Container, Row} from "reactstrap";
import faqImg from "../../assets/images/faq-img.png";
import classnames from "classnames";
import React, {useState} from "react";
import {Link} from "react-router-dom";

const Reports = () => {


    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card className="rounded-0 mx-n4 mt-n4 border-top" style={{backgroundColor:"rgba(94, 53, 177, 0.18)"}}>
                                <div className="px-4">
                                    <Row>
                                        <Col xxl={5} className="align-self-center">
                                            <div className="py-4">
                                                <h4 className="display-6 " style={{color:"#5e35b1"}}>Reports</h4>
                                                {/*<p className=" fs-15 mt-3" style={{color:"#5e35b1"}}>You can always contact us or email us. We will answer you shortly!</p>*/}
                                                {/*<div className="hstack flex-wrap gap-2">*/}
                                                {/*    <button type="button" className="btn btn-primary btn-label rounded-pill"><i className="ri-mail-line label-icon align-middle rounded-pill fs-16 me-2"></i> Email Us</button>*/}
                                                {/*    <button type="button" className="btn btn-info btn-label rounded-pill"><i className="ri-twitter-line label-icon align-middle rounded-pill fs-16 me-2"></i> Send Us Tweet</button>*/}
                                                {/*</div>*/}
                                            </div>
                                        </Col>
                                        {/*<div className="col-xxl-3 ms-auto">*/}
                                        {/*    <div className="mb-n5 pb-1 faq-img d-none d-xxl-block">*/}
                                        {/*        <img src={faqImg} alt="" className="img-fluid"/>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </Row>
                                </div>
                            </Card>
                            <Row className="justify-content-evenly">
                                <Col lg={12}>
                                    <div className="mt-3">
                                        <div className="accordion accordion-border-box" >
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" >
                                                    <a href={`https://api.ashacapital.in/get_employee_report?&token=`+localStorage.getItem('token')} style={{
                                                        "font-weight": 500,
                                                        "color": "var(--vz-accordion-active-color)",
                                                        "padding": "var(--vz-accordion-btn-padding-y) var(--vz-accordion-btn-padding-x)",
                                                        "display": "flex",
                                                        "font-size": "14px"
                                                    }}
                                                       target="_blank"
                                                       rel="noreferrer"
                                                    >All Employees Report</a>

                                                </h2>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" >
                                                    <a href={`https://api.ashacapital.in/get_user_report?&token=`+localStorage.getItem('token')} style={{
                                                        "font-weight": 500,
                                                        "color": "var(--vz-accordion-active-color)",
                                                        "padding": "var(--vz-accordion-btn-padding-y) var(--vz-accordion-btn-padding-x)",
                                                        "display": "flex",
                                                        "font-size": "14px"
                                                    }}
                                                       target="_blank"
                                                       rel="noreferrer"
                                                    >All Users Report</a>

                                                </h2>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" >
                                                    <a href={`https://api.ashacapital.in/get_plan_report?&token=`+localStorage.getItem('token')} style={{
                                                        "font-weight": 500,
                                                        "color": "var(--vz-accordion-active-color)",
                                                        "padding": "var(--vz-accordion-btn-padding-y) var(--vz-accordion-btn-padding-x)",
                                                        "display": "flex",
                                                        "font-size": "14px"
                                                    }}
                                                       target="_blank"
                                                       rel="noreferrer"
                                                    >All Plans Report</a>

                                                </h2>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" >
                                                    <a href={`https://api.ashacapital.in/get_approval_pending_report?&token=`+localStorage.getItem('token')} style={{
                                                        "font-weight": 500,
                                                        "color": "var(--vz-accordion-active-color)",
                                                        "padding": "var(--vz-accordion-btn-padding-y) var(--vz-accordion-btn-padding-x)",
                                                        "display": "flex",
                                                        "font-size": "14px"
                                                    }}
                                                       target="_blank"
                                                       rel="noreferrer"
                                                    >Approval Pending Plans Report</a>

                                                </h2>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" >
                                                    <a href={`https://api.ashacapital.in/get_rejected_plans_report?&token=`+localStorage.getItem('token')} style={{
                                                        "font-weight": 500,
                                                        "color": "var(--vz-accordion-active-color)",
                                                        "padding": "var(--vz-accordion-btn-padding-y) var(--vz-accordion-btn-padding-x)",
                                                        "display": "flex",
                                                        "font-size": "14px"
                                                    }}
                                                       target="_blank"
                                                       rel="noreferrer"
                                                    >Rejected Plans Report</a>

                                                </h2>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" >
                                                    <a href={`https://api.ashacapital.in/get_subscription_report?&token=`+localStorage.getItem('token')} style={{
                                                        "font-weight": 500,
                                                        "color": "var(--vz-accordion-active-color)",
                                                        "padding": "var(--vz-accordion-btn-padding-y) var(--vz-accordion-btn-padding-x)",
                                                        "display": "flex",
                                                        "font-size": "14px"
                                                    }}
                                                       target="_blank"
                                                       rel="noreferrer"
                                                    >Active Plans Report</a>

                                                </h2>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" >
                                                    <a href={`https://api.ashacapital.in/get_completed_plans_report?&token=`+localStorage.getItem('token')} style={{
                                                        "font-weight": 500,
                                                        "color": "var(--vz-accordion-active-color)",
                                                        "padding": "var(--vz-accordion-btn-padding-y) var(--vz-accordion-btn-padding-x)",
                                                        "display": "flex",
                                                        "font-size": "14px"
                                                    }}
                                                       target="_blank"
                                                       rel="noreferrer"
                                                    >Completed Plans Report </a>

                                                </h2>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" >
                                                    <a href={`https://api.ashacapital.in/get_pending_payment_report?&token=`+localStorage.getItem('token')} style={{
                                                        "font-weight": 500,
                                                        "color": "var(--vz-accordion-active-color)",
                                                        "padding": "var(--vz-accordion-btn-padding-y) var(--vz-accordion-btn-padding-x)",
                                                        "display": "flex",
                                                        "font-size": "14px"
                                                    }}
                                                       target="_blank"
                                                       rel="noreferrer"
                                                    >Pending Plan Payments Report</a>

                                                </h2>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" >
                                                    <a href={`https://api.ashacapital.in/get_completed_payment_report?&token=`+localStorage.getItem('token')} style={{
                                                        "font-weight": 500,
                                                        "color": "var(--vz-accordion-active-color)",
                                                        "padding": "var(--vz-accordion-btn-padding-y) var(--vz-accordion-btn-padding-x)",
                                                        "display": "flex",
                                                        "font-size": "14px"
                                                    }}
                                                       target="_blank"
                                                       rel="noreferrer"
                                                    >Completed Plan Payments Report</a>

                                                </h2>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" >
                                                    <a href={`https://api.ashacapital.in/get_pending_refferal_payment_report?&token=`+localStorage.getItem('token')} style={{
                                                        "font-weight": 500,
                                                        "color": "var(--vz-accordion-active-color)",
                                                        "padding": "var(--vz-accordion-btn-padding-y) var(--vz-accordion-btn-padding-x)",
                                                        "display": "flex",
                                                        "font-size": "14px"
                                                    }}
                                                       target="_blank"
                                                       rel="noreferrer"
                                                    >Pending Referral Payments Report</a>

                                                </h2>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" >
                                                    <a href={`https://api.ashacapital.in/get_completed_referral_payment_report?&token=`+localStorage.getItem('token')} style={{
                                                        "font-weight": 500,
                                                        "color": "var(--vz-accordion-active-color)",
                                                        "padding": "var(--vz-accordion-btn-padding-y) var(--vz-accordion-btn-padding-x)",
                                                        "display": "flex",
                                                        "font-size": "14px"
                                                    }}
                                                       target="_blank"
                                                       rel="noreferrer"
                                                    >Completed Referral Payments Report</a>

                                                </h2>
                                            </div>
                                        </div>

                                        </div>
                                </Col>




                            </Row>
                            <br/><br/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>);

}

export default Reports;