import React, {useEffect, useState} from "react";
import {APIClient} from "../../helpers/api_helper";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {Card, CardBody, CardHeader, Col, Container, Input, Label, Row} from "reactstrap";
import Select from "react-select";

const ReferralPaymentView = (props) => {
    const [subscribeId, setSubscribeId] = useState(props.match.params.paymentId);
    const api = new APIClient();
    const [projectList, setProjectList] = useState({});
    const [error, setError] = useState({});
    let [isProjectsSuccess, setisProjectsSuccess] = useState(true);
    const [paymode, setPaymode] = useState(2);
    const [reference_no, setReferenceNo] = useState('');
    async function getProjectsApi() {
        try{
            console.log(subscribeId);
            const res = await api.get(`/get_referrals?id=${subscribeId}`);
            console.log(res);
            setProjectList(res);
            const fields = {};
            fields['plan_id'] = res.plan_id
            fields['type_id'] = res.type_id
            fields['paymode'] = 'bank';
            setInputFields(fields);
            setisProjectsSuccess(true);
        }catch (e){
            console.log(e);
            setError('Invalid Request');
            setisProjectsSuccess(false);
        }
    }

    function getProjectsList(){
        getProjectsApi().then();
    }
    useEffect(() => {
        console.log('page');
        getProjectsList();
    }, []);

    const SingleOptions = [
        { value: 'Watches', label: 'Watches' },
        { value: 'Headset', label: 'Headset' },
        { value: 'Sweatshirt', label: 'Sweatshirt' },
        { value: '20% off', label: '20% off' },
        { value: '4 star', label: '4 star' },
    ];

    const [selectedMulti, setselectedMulti] = useState(null);

    const handleMulti = (selectedMulti) => {
        setselectedMulti(selectedMulti);
    }

    //Dropzone file upload
    const [selectedFiles, setselectedFiles] = useState([]);
    const [files, setFiles] = useState([]);

    const [inputFields, setInputFields] = useState({});
    const [attachments, setAttachments] = useState();

    const handleInputChange = (event) => {
        inputFields[event.target.name] = event.target.value;
        //inputFields['full_name'] = inputFields['first_name'] + ' ' + inputFields['last_name'];
        setInputFields(inputFields);
        console.log(inputFields);
    }

    const handleFileChange = (event) =>{
        setAttachments(event.target.files[0]);
    }

    async function postApi(data) {
        var formData = new FormData();
        inputFields['id'] = subscribeId;
        inputFields['paymode'] = paymode;
        inputFields['reference_no'] = reference_no;
        formData.append("data", JSON.stringify(inputFields));
        console.log(attachments);
        formData.append('file', attachments);
        try{
            const response = await api.create(`/pay_referrals`, formData);
            console.log(response);
            return response;
        }catch (e){
            console.log(e);
        }
    }

    function postData(data){
        postApi(data).then((response) => {
            props.history.push("/referrals-payments");

        });
    }

    const handleSubmit = (event) =>{
        var data = new FormData();
        data.append("data", JSON.stringify(inputFields));
        data.append('file', attachments);
        postData(data);

    }



    const handleCancel = (event) => {
        props.history.push("/referrals-payments");
    }

    async function updateApi(status) {
        try{
            const response = await api.get(`/update-plan-status?status=`+status+`&plan_id=`+subscribeId);
            console.log(response);
            return response;
        }catch (e){
            console.log(e);
        }
    }

    const updateStatus = (status) =>{
        updateApi(status).then((res)=>{
            getProjectsList();
        });
    }

    const handleActivate = (event) => {
        updateStatus('activate');
    }

    const handleDeactivate = (event) => {
        updateStatus('deactivate')
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title=" Plan Details" pageTitle="Plans" />
                    <form id="myForm" method="post" action={'#'} encType="multipart/form-data" onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                        return false;
                    }}>
                        <Row>
                            <Col lg={12}>
                                <Row>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">From User</Label> : {projectList.from_user}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">To User</Label> : {projectList.to_user}
                                                    </div>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Commission Amount</Label> : {projectList.commission}
                                                    </div>
                                                </Col>

                                            </Row>
                                            {/*<Row>*/}
                                            {/*    <Col>*/}
                                            {/*        <div className="mb-3">*/}
                                            {/*            <Label className="form-label" htmlFor="project-title-input">Interest Info</Label> : {projectList.interest_info}*/}

                                            {/*        </div>*/}
                                            {/*    </Col>*/}
                                            {/*    <Col>*/}
                                            {/*        <div className="mb-3">*/}
                                            {/*            <Label className="form-label" htmlFor="project-title-input">Payout</Label>: {projectList.payout}*/}
                                            {/*        </div>*/}
                                            {/*    </Col>*/}

                                            {/*</Row>*/}
                                            {/*<Row>*/}
                                            {/*    <Col>*/}
                                            {/*        <div className="mb-3">*/}
                                            {/*            <Label className="form-label" htmlFor="project-title-input">Amount</Label> : {projectList.amount}*/}

                                            {/*        </div>*/}
                                            {/*    </Col>*/}
                                            {/*    <Col>*/}
                                            {/*        <div className="mb-3">*/}
                                            {/*            <Label className="form-label" htmlFor="project-title-input">EMI Amount</Label>: {projectList.emi_amount}*/}
                                            {/*        </div>*/}
                                            {/*    </Col>*/}

                                            {/*</Row>*/}
                                        </CardBody>
                                    </Card>
                                </Row>
                                <Row>
                                    <Card>
                                        <CardHeader>
                                            <h5 className="card-title mb-0">Bank Details</h5>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Bank Name</Label>
                                                        <Input type="text" className="form-control" id="project-title-input"
                                                               name={'email'} value={projectList.bank_name}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">IFSC Code</Label>
                                                        <Input type="text" className="form-control" name={'ifsc'} value={projectList.ifsc}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Account Number</Label>
                                                        <Input type="text" className="form-control" name={'account_number'} value={projectList.account_no}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Row>
                                <Row>
                                    <Card>
                                        <CardHeader>
                                            <h5 className="card-title mb-0">UPI</h5>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">UPI</Label>
                                                        <Input type="text" className="form-control" id="project-title-input"
                                                               name={'upi'} value={projectList.upi_id}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Row>
                                <Row>
                                    <Card>
                                        <CardHeader>
                                            <h5 className="card-title mb-0">Pay Details</h5>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Payment Mode</Label>
                                                        <select value={paymode} name={'paymode'} className="form-select" data-choices data-choices-search-false onChange={event => setPaymode(event.target.value)}>
                                                            <option value={2}>Bank</option>
                                                            <option value={1}>UPI</option>
                                                        </select>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Reference Number</Label>
                                                        <Input type="text" className="form-control" name={'reference_no'} value={reference_no}
                                                               onChange={event => setReferenceNo(event.target.value)}
                                                               placeholder="Enter Reference No" />
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-thumbnail-img">Reference Screenshot</Label>
                                                        <Input className="form-control" name={'file'} value={inputFields.file}
                                                               onChange={event => handleFileChange(event)} type="file" accept="image/png, image/gif, image/jpeg" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="mb-4">
                                            <button onClick={event => handleCancel(event)} type="button" className="btn btn-danger w-sm me-1">Cancel</button>
                                            <button type="submit" className="btn btn-success w-sm me-1">Submit</button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </form>
                </Container>
            </div>
        </React.Fragment>
    );

}

export default ReferralPaymentView;