import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap';
//Import Flatepicker
import Select from "react-select";
import {APIClient} from "../../connector/client";
import BreadCrumb from "../../Components/Common/BreadCrumb";

const SupportForm = (props) => {
    const api = new APIClient();
    const [phone, setPhone] = useState('9944772292');
    const [email, setEmail] = useState('support@ashacapital.in');
    const [whatsapp, setWhatsapp] = useState('9944772292');
    const [location, setLocation] = useState('9944772292');

    async function getNotesApi() {
        try{
            const res = await api.get(`/support-details`);
            return res;

        }catch (e){
            throw new Error(e);
        }
    }

    function getNotesList(){
        getNotesApi().then((res) => {
            setPhone(res.phone);
            setLocation(res.location);
            setWhatsapp(res.whatsapp);
            setEmail(res.email);
            console.log('success');
        }).catch((error)=>{
            console.log('error');
        });
    }

    // useEffect(() => {
    //     getNotesList();
    // }, []);


    useEffect(() => {
        getNotesList();
    }, []);

    const handleCancel = (event) => {
        props.history.push("/admin-support");
    }

    async function postApi() {
        var data = {};
        data['phone'] = phone;
        data['email'] = email;
        data['whatsapp'] = whatsapp;
        data['location'] = location;
        try{
            const response = await api.create(`/support`, data);
            console.log(response);
            return response;
        }catch (e){
            console.log(e);
        }
    }

    function postData(){
        postApi().then((response) => {
            alert('Details Update Successfully');
            props.history.push("/admin-support");

        });
    }

    const handleSubmit = (event) =>{
        postData();

    }



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Support Details" pageTitle="Asha Capitals" />
                    <form id="myForm" method="post" action={'#'} encType="multipart/form-data" onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                        return false;
                    }}>
                        <Row>
                            <Col lg={8}>

                                <Row>
                                    <Card>
                                        <CardHeader>
                                            <h5 className="card-title mb-0">Support Information</h5>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Phone</Label>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="Enter Phone" name={'phone'} value={phone}
                                                            onChange={event => setPhone(event.target.value)}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">E-Mail</Label>
                                                        <Input
                                                            type="email"
                                                            className="form-control"
                                                            placeholder="Enter Email" name={'email'} value={email}
                                                            onChange={event => setEmail(event.target.value)}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Whatsapp</Label>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="Enter Whatsapp" name={'whatsapp'} value={whatsapp}
                                                            onChange={event => setWhatsapp(event.target.value)}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Address</Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Address" name={'location'} value={location}
                                                            onChange={event => setLocation(event.target.value)}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                        </CardBody>
                                    </Card>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="mb-4">
                                            <button onClick={event => handleCancel(event)} type="button" className="btn btn-danger w-sm me-1">Cancel</button>
                                            <button type="submit" className="btn btn-success w-sm me-1">Submit</button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    </form>


                </Container>
            </div>
        </React.Fragment>
    );
};

export default SupportForm;