import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

const Services = () => {
    return (
        <React.Fragment>
            <section className="section" id="services">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <div className="text-center mb-5">
                                <h1 className="mb-3 ff-secondary fw-semibold lh-base">Vision</h1>
                                <p className="text-muted">Our vision is to provide exceptional forex fund management services and help clients achieve their financial goals through successful trading strategies.</p>
                            <h1 className="mb-3 ff-secondary fw-semibold lh-base">Mission</h1>
                                <p className="text-muted">Our mission is to deliver consistent profits and outstanding results for our clients by employing advanced trading techniques, rigorous risk management, and a client-centric approach.</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="g-3">
                        <Col lg={3}>
                            <div className="d-flex p-3">
                                <div className="flex-shrink-0 me-3">
                                    <div className="avatar-sm icon-effect">
                                        <div className="avatar-title bg-transparent text-success rounded-circle">
                                            <i className="ri-pencil-ruler-2-line fs-36"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-grow-1">
                                    <h5 className="fs-18">Market Size</h5>
                                    <p className="text-muted my-3 ff-secondary">The forex market is the largest and most liquid market globally, with trillions of dollars changing hands every day.</p>

                                </div>
                            </div>
                        </Col>

                        <Col lg={3}>
                            <div className="d-flex p-3">
                                <div className="flex-shrink-0 me-3">
                                    <div className="avatar-sm icon-effect">
                                        <div className="avatar-title bg-transparent text-success rounded-circle">
                                            <i className="ri-palette-line fs-36"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-grow-1">
                                    <h5 className="fs-18">Accessibility</h5>
                                    <p className="text-muted my-3 ff-secondary">Most trading occurs through banks, individuals, companies, and financial institutions, making it accessible to a wide range of participants.</p>

                                </div>
                            </div>
                        </Col>

                        <Col lg={3}>
                            <div className="d-flex p-3">
                                <div className="flex-shrink-0 me-3">
                                    <div className="avatar-sm icon-effect">
                                        <div className="avatar-title bg-transparent text-success rounded-circle">
                                            <i className="ri-lightbulb-flash-line fs-36"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-grow-1">
                                    <h5 className="fs-18">Operating Hours</h5>
                                    <p className="text-muted my-3 ff-secondary">The forex market operates 24 hours a day, five days a week, allowing for continuous trading across different time zones. It remains open on many holidays when stock markets are closed, although trading volume may vary.</p>

                                </div>
                            </div>
                        </Col>

                        <Col lg={3}>
                            <div className="d-flex p-3">
                                <div className="flex-shrink-0 me-3">
                                    <div className="avatar-sm icon-effect">
                                        <div className="avatar-title bg-transparent text-success rounded-circle">
                                            <i className="ri-customer-service-line fs-36"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-grow-1">
                                    <h5 className="fs-18">Portmanteau of Foreign Exchange</h5>
                                    <p className="text-muted my-3 ff-secondary">The term "forex" is derived from foreign and exchange, often abbreviated as FX.</p>

                                </div>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default Services;