import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap';
//Import Flatepicker
import Select from "react-select";
import {APIClient} from "../../connector/client";
import BreadCrumb from "../../Components/Common/BreadCrumb";

const SuggestionForm = (props) => {
    const api = new APIClient();
    const [type, setType] = useState(1);
    const [suggestion, setSuggestion] = useState('');



    const handleCancel = (event) => {
        props.history.push("/my-suggestions");
    }

    async function postApi() {
        var data = {};
        data['type'] = type;
        data['suggestion'] = suggestion;
        try{
            const response = await api.create(`/suggestion`, data);
            console.log(response);
            return response;
        }catch (e){
            console.log(e);
        }
    }

    function postData(){
        postApi().then((response) => {
            props.history.push("/my-suggestions");

        });
    }

    const handleSubmit = (event) =>{
        postData();

    }



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Add Suggestion" pageTitle="Suggestions" />
                    <form id="myForm" method="post" action={'#'} encType="multipart/form-data" onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                        return false;
                    }}>
                        <Row>
                            <Col lg={8}>

                                <Row>
                                    <Card>
                                        <CardHeader>
                                            <h5 className="card-title mb-0">Suggestion/Complaint Box</h5>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Suggestion Type</Label>
                                                        <select value={type} name={'type'} className="form-select" data-choices data-choices-search-false onChange={event => setType(event.target.value)}>
                                                            <option value={1}>Suggestion</option>
                                                            <option value={2}>Compliants</option>
                                                        </select>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Suggestion / Complaints</Label>
                                                        <textarea className="form-control" name={"suggestion"} value={suggestion} placeholder="Enter Suggestion/Complaints" onChange={event => setSuggestion(event.target.value)}></textarea>

                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="mb-4">
                                            <button onClick={event => handleCancel(event)} type="button" className="btn btn-danger w-sm me-1">Cancel</button>
                                            <button type="submit" className="btn btn-success w-sm me-1">Submit</button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    </form>


                </Container>
            </div>
        </React.Fragment>
    );
};

export default SuggestionForm;