import {useEffect} from "react";
import {APIClient} from "../../connector/client";

const SwitchView = (props) => {
    const api = new APIClient();
    async function postApi() {
        try{
            const response = await api.get(`/get_current_user_info`);
            console.log(response);
            return response;
        }catch (e){
            console.log(e);
        }
    }

    function postData(){
        postApi().then((response) => {
            if(response.role === 0){
                if(response.can_edit){
                    window.location.href="/profile";
                }else{
                    window.location.href="/my-active-plans";
                }
            }else{
                window.location.href='/profile';
            }


        });
    }

    useEffect(() => {
        postData();
    }, []);

    return(<>
        Loading
    </>);

}

export default SwitchView;