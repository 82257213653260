import {useDispatch} from "react-redux";
import {APIClient} from "../../helpers/api_helper";
import React, {useEffect, useState} from "react";
import {ToastContainer} from "react-toastify";
import {Row, Table} from "reactstrap";
import {Link} from "react-router-dom";
import {AsyncPaginate} from "react-select-async-paginate";
import Loader from "../../Components/Common/Loader";

const RejectedSubscriptionList = () => {
    const dispatch = useDispatch();
    const api = new APIClient();
    let[value,setValue] = useState({});
    let [selectOptions,setSelectedOptions] = useState([]);
    let [selectedVal, setSelectedVal] = useState('');
    const [noteList, setNoteList] = useState([]);
    const [error, setError] = useState({});
    let [page, setPage] = useState(1);
    let[pagination, setPagination] = useState({'has_previous': false, 'has_next': false, 'index':1, limit:10});
    let [isNotesSuccess, setisNotesSuccess] = useState(true);

    async function getNotesApi() {
        try{
            const res = await api.get(`/get-rejected-subscription`);
            console.log(res);
            setNoteList(res);
            setPagination(res.pagination);
            setisNotesSuccess(true);
            console.log(noteList);
            console.log('success');
        }catch (e){
            console.log(e);
            setError('Invalid Request');
            setisNotesSuccess(false);
        }
    }

    function getNotesList(){
        getNotesApi().then();
    }

    // useEffect(() => {
    //     getNotesList();
    // }, []);


    useEffect(() => {
        getNotesList();
    }, []);


    const logChange = (val) =>{
        console.log(val.id);
        console.log(val.value);
        setSelectedVal(val.id);
        setValue({value:val.value,label:val.label});
        const url = "http://localhost:8000/mdl/grades?page=1"+"&q="+val.id;
        // fetch.get(url)
        //     .then(function (response) {
        //         console.log(response.data);
        //         //setData(response.data['data']);
        //         //setPagination(response.data['pagination']);
        //     })
        //     .catch(function (error) {
        //         // handle error
        //         //console.log(error);
        //     })
        //     .then(function () {
        //         // always executed
        //     });
    }

    const getOptions= async (search, loadedOptions, { page }) =>{
        console.log('page'+page);
        console.log('search'+search);
        let url= `/mdl/search/grades/all`;
        const res = await api.get(url,{"q":`${search}`,"page":`${page}`,"search":true});
        const resp_data = res.data;
        const data = resp_data;
        const pagination = res.pagination;
        const options = data.map(d => ({
            "id" : d.id,
            "value": d.name,
            "label": d.name,
        }))

        setSelectedOptions(options);
        let npage = pagination.index + 1;
        return {
            options: options,
            hasMore: pagination.has_next,
            additional: {
                page: npage,
            },
        };

    };
    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            <Row>
                <div className="card">
                    <div className="table-responsive">
                        <Table className="align-middle table-nowrap mb-0">
                            <thead>
                            <tr>
                                <th scope="col">Plan Name</th>
                                <th scope="col">Plan Type</th>
                                <th scope="col">Amount</th>
                                <th scope="col">User</th>
                                <th scope="col">Created Time</th>
                            </tr>
                            </thead>
                            <tbody>
                            {isNotesSuccess  ? (
                                (noteList || []).map((item, index) => (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <th scope="row"><Link to={`/rejected-subscription/${item.id}`} >{item.plan.name}</Link></th>
                                            <td>{item.type.name}</td>
                                            <td>{item.plan.amount}</td>
                                            <td>{item.user.name}</td>
                                            <td>{(new Date(item.created_time)).toLocaleString()}</td>
                                        </tr>
                                    </React.Fragment>
                                ))
                            ) : (<Loader error={error} />)
                            }

                            </tbody>
                        </Table>

                    </div>
                </div>
            </Row>
            {/*{isNotesSuccess  ? (*/}
            {/*    <Row className="g-0 text-center text-sm-start align-items-center mb-4">*/}
            {/*        <Col sm={6}>*/}
            {/*        </Col>*/}
            {/*        <Col sm={6}>*/}
            {/*            <ul className="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">*/}
            {/*                {pagination.has_previous &&*/}
            {/*                    <li className="page-item active">*/}
            {/*                        <Link  onClick={() => setPage(pagination.index - 1)} className="page-link">Previous</Link>*/}
            {/*                    </li>*/}
            {/*                }*/}
            {/*                {!pagination.has_previous &&*/}
            {/*                    <li className="page-item disabled">*/}
            {/*                        <Link  className="page-link">Previous</Link>*/}
            {/*                    </li>*/}
            {/*                }*/}

            {/*                <li className="page-item active">*/}
            {/*                    <Link  className="page-link">{pagination.index}</Link>*/}
            {/*                </li>*/}
            {/*                {pagination.has_next &&*/}
            {/*                    <li className="page-item active">*/}
            {/*                        <Link  onClick={() => setPage(pagination.index + 1)} className="page-link">Next</Link>*/}
            {/*                    </li>*/}
            {/*                }*/}
            {/*                {!pagination.has_next &&*/}
            {/*                    <li className="page-item disabled">*/}
            {/*                        <Link className="page-link">Next</Link>*/}
            {/*                    </li>*/}
            {/*                }*/}

            {/*            </ul>*/}
            {/*        </Col>*/}
            {/*    </Row>*/}

            {/*) :(<></>)}*/}
        </React.Fragment>
    );
}
export default RejectedSubscriptionList;