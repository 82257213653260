import BreadCrumb from "../../Components/Common/BreadCrumb";
import React from "react";
import {Container} from "reactstrap";
import PendingApprovalList from "./PendingApprovalList";

const PendingApprovalsView = () => {

    return(
      <React.Fragment>
          <div className="page-content">
              <Container fluid>
                  <BreadCrumb title="Pending Approvals" pageTitle="Approvals" />
                  <PendingApprovalList/>
              </Container>
          </div>
      </React.Fragment>
    );
}
export default PendingApprovalsView;