import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {APIClient} from "../helpers/api_helper";
import Loader from "../Components/Common/Loader";

const Navdata = () => {
    const api = new APIClient();
    const [noteList, setNoteList] = useState({});
    const [isUser, setIsUser] = useState(false);
    const [isEmployee, setIsEmployee] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [error, setError] = useState({});
    let [page, setPage] = useState(1);
    let[pagination, setPagination] = useState({'has_previous': false, 'has_next': false, 'index':1, limit:10});
    let [isNotesSuccess, setisNotesSuccess] = useState(true);
    async function getNotesApi() {
        try{
            const res = await api.get(`/get_role`);
            console.log(res);
            setNoteList(res);
            setIsUser(res.is_user);
            setIsAdmin(res.is_admin);
            setIsEmployee(res.is_employee);
            setisNotesSuccess(true);
            console.log('success');
        }catch (e){
            console.log(e);
            setError('Invalid Request');
            setisNotesSuccess(false);
        }
    }

    function getNotesList(){
        getNotesApi().then();
    }

    // useEffect(() => {
    //     getNotesList();
    // }, []);


    useEffect(() => {
        getNotesList();
    }, []);
    const history = useHistory();
    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const [isApps, setIsApps] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [isPages, setIsPages] = useState(false);
    const [isBaseUi, setIsBaseUi] = useState(false);
    const [isAdvanceUi, setIsAdvanceUi] = useState(false);
    const [isForms, setIsForms] = useState(false);
    const [isTables, setIsTables] = useState(false);
    const [isCharts, setIsCharts] = useState(false);
    const [isIcons, setIsIcons] = useState(false);
    const [isMaps, setIsMaps] = useState(false);
    const [isMultiLevel, setIsMultiLevel] = useState(false);

    // Apps
    const [isProjects, setIsProjects] = useState(false);
    const [isChat, setIsChat] = useState(false);
    const [isCalendar, setIsCalendar] = useState(false);
    const [isTimeline, setIsTimeline] = useState(false);
    const [isToDo, setIsToDo] = useState(false);
    const [isNotes, setIsNotes] = useState(false);
    const [isBugs, setIsBugs] = useState(false);
    const [isLanding, setIsLanding] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e) {
        console.log(e);
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if (iscurrentState !== 'Apps') {
            setIsApps(false);
        }
        if (iscurrentState !== 'Auth') {
            setIsAuth(false);
        }
        if (iscurrentState !== 'Pages') {
            setIsPages(false);
        }
        if (iscurrentState !== 'BaseUi') {
            setIsBaseUi(false);
        }
        if (iscurrentState !== 'AdvanceUi') {
            setIsAdvanceUi(false);
        }
        if (iscurrentState !== 'Forms') {
            setIsForms(false);
        }
        if (iscurrentState !== 'Tables') {
            setIsTables(false);
        }
        if (iscurrentState !== 'Charts') {
            setIsCharts(false);
        }
        if (iscurrentState !== 'Icons') {
            setIsIcons(false);
        }
        if (iscurrentState !== 'Maps') {
            setIsMaps(false);
        }
        if (iscurrentState !== 'MuliLevel') {
            setIsMultiLevel(false);
        }
        if (iscurrentState === 'Widgets') {
            history.push("/widgets");
            document.body.classList.add('twocolumn-panel');
        }
        if (iscurrentState !== 'Landing') {
            setIsLanding(false);
        }
        if (iscurrentState !== 'Bugs') {
            setIsBugs(false);
        }
    }, [
        history,
        iscurrentState,
        isDashboard,
        isApps,
        isAuth,
        isPages,
        isBaseUi,
        isAdvanceUi,
        isForms,
        isTables,
        isCharts,
        isIcons,
        isMaps,
        isMultiLevel,
        isBugs
    ]);

    const menuItems = [
        {
            id: "dashboard",
            label: "Profile",
            icon: "ri-contacts-fill",
            link: "/profile",
        },
        {
            id: "timetable",
            label: "Income Type",
            icon: "ri-price-tag-2-fill",
            link: "/income-type",
        },
        {
            id: "timetable",
            label: "Special Offers",
            icon: "ri-price-tag-2-fill",
            link: "/my-offers",
        },
        {
            id: "curriculum",
            label: "Offer Plans",
            icon: " ri-coupon-fill",
            link: "/offer-plans",
        },
        {
            id: "attendance",
            label: "My Plans",
            icon: "  ri-flag-2-fill",
            link: "/my-active-plans",
        },
        {
            id: "events",
            label: "Completed Plans",
            icon: "ri-focus-3-fill",
            link: "/my-completed-plans",
        },
        {
            id: "attendance",
            label: "Rejected Subscription",
            icon: "  ri-flag-2-fill",
            link: "/rejected-subscription",
        },
        {
            id: "exam",
            label: "Referrals",
            icon: " ri-award-fill",
            link: "/my-referrals",
        },
        {
            id: "chat",
            label: "Suggestions",
            icon: "ri-survey-fill",
            link: "/my-suggestions",
        },
        {
            id: "documents",
            label: "Feedback",
            icon: " ri-feedback-fill",
            link: "/my-feedbacks",
        },
        {
            id: "timeline",
            label: "Support",
            icon: "ri-headphone-fill",
            link: "/support",
        },
    ];
    const adminMenuItems = [
        {
            id: "dashboard",
            label: "Profile",
            icon: "ri-contacts-fill",
            link: "/profile",
        },
        {
            id: "profile-edit",
            label: "Profile Request",
            icon: "ri-contacts-fill",
            link: "/profile-edit-request",
        },
        {
            id: "users",
            label: "Users",
            icon: "ri-contacts-fill",
            link: "/users",
        },
        {
            id: "timetable",
            label: "Special Offers",
            icon: "ri-price-tag-2-fill",
            link: "/special-offers",
        },
        {
            id: "curriculum",
            label: "Plans",
            icon: " ri-coupon-fill",
            link: "/plans",
        },
        {
            id: "attendance",
            label: "Pending Payments",
            icon: "  ri-flag-2-fill",
            link: "/pending-payments",
        },
        {
            id: "attendance",
            label: "Pending Approvals",
            icon: "  ri-flag-2-fill",
            link: "/pending-approvals",
        },
        {
            id: "exam",
            label: "Referrals",
            icon: " ri-award-fill",
            link: "/referrals-payments",
        },
        {
            id: "chat",
            label: "Suggestions",
            icon: "ri-survey-fill",
            link: "/suggestions",
        },
        {
            id: "documents",
            label: "Feedback",
            icon: " ri-feedback-fill",
            link: "/feedbacks",
        },
        {
            id: "timeline",
            label: "Support",
            icon: "ri-headphone-fill",
            link: "/admin-support",
        },
        {
            id: "timeline",
            label: "Reports",
            icon: " ri-bubble-chart-fill",
            link: "/reports",
        },
    ];
    const employeeMenuItems = [
        {
            id: "dashboard",
            label: "Profile",
            icon: "ri-contacts-fill",
            link: "/profile",
            stateVariables: isDashboard,
            click: function (e) {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                setIscurrentState('Dashboard');
                updateIconSidebar(e);
            },
        },
        {
            id: "users",
            label: "Users",
            icon: "ri-contacts-fill",
            link: "/users",
            stateVariables: isDashboard,
            click: function (e) {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                setIscurrentState('Dashboard');
                updateIconSidebar(e);
            },
        },
        {
            id: "profile",
            label: "Special Offers",
            icon: "ri-trophy-fill",
            link: "/projects",
            stateVariables: isProjects,
            click: function (e) {
                e.preventDefault();
                setIsProjects(!isProjects);
                setIscurrentState('Profile');
                updateIconSidebar(e);
            }
        },
        {
            id: "timetable",
            label: "Income Type",
            icon: "ri-price-tag-2-fill",
            link: "/income-type",
            stateVariables: isToDo,
            click: function (e) {
                e.preventDefault();
                setIsToDo(!isToDo);
                setIscurrentState('TimeTable');
                updateIconSidebar(e);
            }
        },
        {
            id: "curriculum",
            label: "Offer Plans",
            icon: " ri-coupon-fill",
            link: "/offer-plans",
            stateVariables: isToDo,
            click: function (e) {
                e.preventDefault();
                setIsToDo(!isToDo);
                setIscurrentState('Curriculum');
                updateIconSidebar(e);
            }
        },
        {
            id: "events",
            label: "Current Plans",
            icon: "ri-focus-3-fill",
            link: "/current-plans",
            stateVariables: isNotes,
            click: function (e) {
                e.preventDefault();
                setIsNotes(!isNotes);
                setIscurrentState('Events');
                updateIconSidebar(e);
            }
        },
        {
            id: "events",
            label: "All Plans",
            icon: "ri-focus-3-fill",
            link: "/all-plans",
            stateVariables: isNotes,
            click: function (e) {
                e.preventDefault();
                setIsNotes(!isNotes);
                setIscurrentState('Events');
                updateIconSidebar(e);
            }
        },
        {
            id: "attendance",
            label: "Pending Payments",
            icon: "  ri-flag-2-fill",
            link: "/pending-payments",
            stateVariables: isBugs,
            click: function (e) {
                e.preventDefault();
                setIsBugs(!isBugs);
                setIscurrentState('Attendance');
                updateIconSidebar(e);
            }
        },
        {
            id: "attendance",
            label: "Pending Approvals",
            icon: "  ri-flag-2-fill",
            link: "/pending-approvals",
            stateVariables: isBugs,
            click: function (e) {
                e.preventDefault();
                setIsBugs(!isBugs);
                setIscurrentState('Attendance');
                updateIconSidebar(e);
            }
        },
        {
            id: "homework",
            label: "Completed Plans",
            icon: " ri-bank-card-fill",
            link: "/completed-plans",
            stateVariables: isCalendar,
            click: function (e) {
                e.preventDefault();
                setIsCalendar(!isCalendar);
                setIscurrentState('Homework');
                updateIconSidebar(e);
            }
        },
        {
            id: "exam",
            label: "Referrals",
            icon: " ri-award-fill",
            link: "/referrals",
            stateVariables: isCalendar,
            click: function (e) {
                e.preventDefault();
                setIsCalendar(!isCalendar);
                setIscurrentState('Exam');
                updateIconSidebar(e);
            }
        },
        {
            id: "chat",
            label: "Suggestions",
            icon: "ri-survey-fill",
            link: "/suggestions",
            stateVariables: isChat,
            click: function (e) {
                e.preventDefault();
                setIsChat(!isChat);
                setIscurrentState('Chat');
                updateIconSidebar(e);
            }
        },
        {
            id: "documents",
            label: "Feedback",
            icon: " ri-feedback-fill",
            link: "/feedback",
            stateVariables: isProjects,
            click: function (e) {
                e.preventDefault();
                setIsProjects(!isProjects);
                setIscurrentState('Projects');
                updateIconSidebar(e);
            }
        },
        {
            id: "timeline",
            label: "Support",
            icon: "ri-headphone-fill",
            link: "/support",
            stateVariables: isTimeline,
            click: function (e) {
                e.preventDefault();
                setIsTimeline(!isTimeline);
                setIscurrentState('Timeline');
                updateIconSidebar(e);
            }
        },
    ];
    const referralPaymentItems = [{
        id: "dashboard",
        label: "Profile",
        icon: "ri-contacts-fill",
        link: "/profile",
    },
        {
            id: "attendance",
            label: "Referral Payments",
            icon: "  ri-award-fill",
            link: "/referrals-payments",
        }];
    const clientPaymentItems = [
        {
            id: "dashboard",
            label: "Profile",
            icon: "ri-contacts-fill",
            link: "/profile",
        },
        {
            id: "attendance",
            label: "Pending Payments",
            icon: "  ri-flag-2-fill",
            link: "/pending-payments",
        }
    ];
    const paymentApprovalItems = [{
        id: "dashboard",
        label: "Profile",
        icon: "ri-contacts-fill",
        link: "/profile",
    },
        {
            id: "attendance",
            label: "Pending Approvals",
            icon: "  ri-flag-2-fill",
            link: "/pending-approvals",
        }];
    const profileEditItems = [{
        id: "profile",
        label: "Profile",
        icon: "ri-contacts-fill",
        link: "/profile",
    },
        {
            id: "profile_edit",
            label: "Profile Edit",
            icon: "ri-contacts-fill",
            link: "/profile-edit-request",
        }];
    const specialOfferItems = [];
    const pendingPaymentsItems = [{
        id: "profile",
        label: "Profile",
        icon: "ri-contacts-fill",
        link: "/profile",
        },
        {
            id: "pending_payments",
            label: "Pending Payments",
            icon: "  ri-flag-2-fill",
            link: "/pending-payments",
        }];
    const suggestionItems = [{
        id: "profile",
        label: "Profile",
        icon: "ri-contacts-fill",
        link: "/profile",
    },
        {
            id: "pending_payments",
            label: "Pending Payments",
            icon: "  ri-flag-2-fill",
            link: "/suggestion",
        }];
    const supportItems = [{
        id: "profile",
        label: "Profile",
        icon: "ri-contacts-fill",
        link: "/profile",
    },
        {
            id: "support",
            label: "Pending Payments",
            icon: "  ri-flag-2-fill",
            link: "/support",
        }];
    const menuData = {'user': menuItems, 'admin': adminMenuItems, 'employee': employeeMenuItems,'referral_payment':referralPaymentItems,
    'client_payment': clientPaymentItems,'payment_approval': paymentApprovalItems,'profile_edit':profileEditItems,'special_offer':specialOfferItems,
    'pending_payment':pendingPaymentsItems,'suggestion':suggestionItems,'support':supportItems}

    return <React.Fragment>{menuData}</React.Fragment>;

};
export default Navdata;