import BreadCrumb from "../../Components/Common/BreadCrumb";
import React from "react";
import {Container} from "reactstrap";
import RejectedSubscriptionList from "./RejectedSubscriptionList";

const RejectedSubscription = () => {

    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Rejected Subscription" pageTitle="Subscription" />
                    <RejectedSubscriptionList/>
                </Container>
            </div>
        </React.Fragment>
    );
}
export default RejectedSubscription;