import React, {useEffect, useState} from "react";
import {ToastContainer} from "react-toastify";
import {Card, Col, Input, Row, Table} from "reactstrap";
import {Link} from "react-router-dom";
import {AsyncPaginate} from "react-select-async-paginate";
import axios from "axios";
import {useDispatch} from "react-redux";
import {APIClient} from "../../connector/client";
import Loader from "../../Components/Common/Loader";
import {FaStar} from "react-icons/fa";

const MyRatingList = () => {

    const dispatch = useDispatch();
    const api = new APIClient();
    let[value,setValue] = useState({});
    let [selectOptions,setSelectedOptions] = useState([]);
    let [selectedVal, setSelectedVal] = useState('');
    const [noteList, setNoteList] = useState([]);
    const [error, setError] = useState({});
    let [page, setPage] = useState(1);
    let[pagination, setPagination] = useState({'has_previous': false, 'has_next': false, 'index':1, limit:10});
    let [isNotesSuccess, setisNotesSuccess] = useState(true);
    let [noData, setNoData] = useState(false);

    async function getNotesApi() {
        try{
            const res = await api.get(`/my-rating?page=${page}`);
            return res;

        }catch (e){
            throw new Error(e);
        }
    }

    function getNotesList(){
        getNotesApi().then((res) => {
            setNoteList(res);
            setPagination(res.pagination);
            setisNotesSuccess(true);
            if(res.length === 0 && page===1){
                setNoData(true);
            }else{
                setNoData(false);
            }
            console.log(noteList);
            console.log('success');
        }).catch((error)=>{
            console.log('inside error');
            console.log(error);
            setError('Invalid Request');
            setisNotesSuccess(false);
        });
    }

    // useEffect(() => {
    //     getNotesList();
    // }, []);


    useEffect(() => {
        getNotesList();
    }, []);


    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            <Row className="g-4 mb-3">
                <div className="col-sm-auto">
                    <div>
                        <Link to="/my-feedbacks/form/create" className="btn btn-success"><i
                            className="ri-add-line align-bottom me-1"></i> Add Rating</Link>
                    </div>
                </div>
            </Row>
            <Row>
                <Card style={{minHeight:'200px'}}>


                    {noData ? (<>
                        <center style={{paddingTop:'100px'}}>
                            No Data Found
                        </center>

                    </>) : (

                        <div className="table-responsive">
                            <Table className="align-middle table-nowrap mb-0">
                                <thead>
                                <tr>
                                    <th scope="col">Rating Star</th>
                                    <th scope="col">User</th>
                                    <th scope="col">Feedback</th>
                                </tr>
                                </thead>
                                <tbody>
                                {isNotesSuccess  ? (
                                    (noteList || []).map((item, index) => (
                                        <React.Fragment key={index}>
                                            <tr>
                                                <td>
                                                    {item.star_count == 1 ?(<><FaStar color={"rgb(192,192,192)"}/></>):(<></>)}
                                                    {item.star_count == 2 ?(<><FaStar color={"rgb(192,192,192)"}/><FaStar color={"rgb(192,192,192)"}/></>):(<></>)}
                                                    {item.star_count == 3 ?(<><FaStar color={"rgb(192,192,192)"}/><FaStar color={"rgb(192,192,192)"}/><FaStar color={"rgb(192,192,192)"}/></>):(<></>)}
                                                    {item.star_count == 4 ?(<><FaStar color={"rgb(192,192,192)"}/><FaStar color={"rgb(192,192,192)"}/><FaStar color={"rgb(192,192,192)"}/><FaStar color={"rgb(192,192,192)"}/></>):(<></>)}
                                                    {item.star_count == 5 ?(<><FaStar color={"rgb(192,192,192)"}/><FaStar color={"rgb(192,192,192)"}/><FaStar color={"rgb(192,192,192)"}/><FaStar color={"rgb(192,192,192)"}/><FaStar color={"rgb(192,192,192)"}/></>):(<></>)}
                                                </td>
                                                <td>{item.user}
                                                </td>
                                                <td>{item.feedback}</td>

                                            </tr>
                                        </React.Fragment>
                                    ))
                                ) : (<Loader error={error} />)
                                }

                                </tbody>
                            </Table>

                        </div>

                    )}
                </Card>
            </Row>
            {/*{isNotesSuccess  ? (*/}
            {/*    <Row className="g-0 text-center text-sm-start align-items-center mb-4">*/}
            {/*        <Col sm={6}>*/}
            {/*        </Col>*/}
            {/*        <Col sm={6}>*/}
            {/*            <ul className="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">*/}
            {/*                {pagination.has_previous &&*/}
            {/*                    <li className="page-item active">*/}
            {/*                        <Link  onClick={() => setPage(pagination.index - 1)} className="page-link">Previous</Link>*/}
            {/*                    </li>*/}
            {/*                }*/}
            {/*                {!pagination.has_previous &&*/}
            {/*                    <li className="page-item disabled">*/}
            {/*                        <Link  className="page-link">Previous</Link>*/}
            {/*                    </li>*/}
            {/*                }*/}

            {/*                <li className="page-item active">*/}
            {/*                    <Link  className="page-link">{pagination.index}</Link>*/}
            {/*                </li>*/}
            {/*                {pagination.has_next &&*/}
            {/*                    <li className="page-item active">*/}
            {/*                        <Link  onClick={() => setPage(pagination.index + 1)} className="page-link">Next</Link>*/}
            {/*                    </li>*/}
            {/*                }*/}
            {/*                {!pagination.has_next &&*/}
            {/*                    <li className="page-item disabled">*/}
            {/*                        <Link className="page-link">Next</Link>*/}
            {/*                    </li>*/}
            {/*                }*/}

            {/*            </ul>*/}
            {/*        </Col>*/}
            {/*    </Row>*/}

            {/*) :(<></>)}*/}
        </React.Fragment>
    );
}
export default MyRatingList;