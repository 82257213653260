import {Card, Col, Collapse, Container, Row, Table} from "reactstrap";
import faqImg from "../../assets/images/faq-img.png";
import classnames from "classnames";
import React, {useEffect, useState} from "react";
import {APIClient} from "../../helpers/api_helper";
import Loader from "../../Components/Common/Loader";

const MyCompletedPlans = () => {
    const [col1, setcol1] = useState(true)
    const [col2, setcol2] = useState(false)
    const [col3, setcol3] = useState(false)
    const [col4, setcol4] = useState(false)

    const [col5, setcol5] = useState(false)
    const [col6, setcol6] = useState(true)
    const [col7, setcol7] = useState(false)
    const [col8, setcol8] = useState(false)

    const [col9, setcol9] = useState(true)
    const [col10, setcol10] = useState(false)
    const [col11, setcol11] = useState(false)
    const [col12, setcol12] = useState(false)
    const api = new APIClient();
    const [noteList, setNoteList] = useState([]);
    const [error, setError] = useState({});
    let [page, setPage] = useState(1);
    let[pagination, setPagination] = useState({'has_previous': false, 'has_next': false, 'index':1, limit:10});
    let [isNotesSuccess, setisNotesSuccess] = useState(true);

    async function getNotesApi() {
        try{
            const res = await api.get(`/my-completed-plans`);
            console.log(res);
            setNoteList(res);
            setPagination(res.pagination);
            setisNotesSuccess(true);
            console.log(noteList);
            console.log('success');
        }catch (e){
            console.log(e);
            setError('Invalid Request');
            setisNotesSuccess(false);
        }
    }

    function getNotesList(){
        getNotesApi().then();
    }

    // useEffect(() => {
    //     getNotesList();
    // }, []);


    useEffect(() => {
        getNotesList();
    }, []);


    const t_col1 = () => {
        setcol1(!col1)
        setcol2(false)
        setcol3(false)
        setcol4(false)

    }

    const t_col2 = () => {
        setcol2(!col2)
        setcol1(false)
        setcol3(false)
        setcol4(false)

    }

    const t_col3 = () => {
        setcol3(!col3)
        setcol1(false)
        setcol2(false)
        setcol4(false)

    }

    const t_col4 = () => {
        setcol4(!col4)
        setcol1(false)
        setcol2(false)
        setcol3(false)
    }

    const t_col5 = () => {
        setcol5(!col5)
        setcol6(false)
        setcol7(false)
        setcol8(false)

    }

    const t_col6 = () => {
        setcol6(!col6)
        setcol5(false)
        setcol7(false)
        setcol8(false)

    }

    const t_col7 = () => {
        setcol7(!col7)
        setcol6(false)
        setcol5(false)
        setcol8(false)

    }

    const t_col8 = () => {
        setcol8(!col8)
        setcol5(false)
        setcol6(false)
        setcol7(false)
    }

    const t_col9 = () => {
        setcol9(!col9)
        setcol10(false)
        setcol11(false)
        setcol12(false)

    }

    const t_col10 = () => {
        setcol10(!col10)
        setcol11(false)
        setcol12(false)
        setcol9(false)

    }

    const t_col11 = () => {
        setcol11(!col11)
        setcol9(false)
        setcol10(false)
        setcol12(false)

    }

    const t_col12 = () => {
        setcol12(!col12)
        setcol9(false)
        setcol10(false)
        setcol11(false)
    }

    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card className="rounded-0 mx-n4 mt-n4 border-top" style={{backgroundColor:"rgba(94, 53, 177, 0.18)"}}>
                                <div className="px-4">
                                    <Row>
                                        <Col xxl={5} className="align-self-center">
                                            <div className="py-4">
                                                <h4 className="display-6 " style={{color:"#5e35b1"}}>My Completed Plans</h4>
                                                <p className=" fs-15 mt-3" style={{color:"#5e35b1"}}>You can always contact us or email us. We will answer you shortly!</p>
                                                {/*<div className="hstack flex-wrap gap-2">*/}
                                                {/*    <button type="button" className="btn btn-primary btn-label rounded-pill"><i className="ri-mail-line label-icon align-middle rounded-pill fs-16 me-2"></i> Email Us</button>*/}
                                                {/*    <button type="button" className="btn btn-info btn-label rounded-pill"><i className="ri-twitter-line label-icon align-middle rounded-pill fs-16 me-2"></i> Send Us Tweet</button>*/}
                                                {/*</div>*/}
                                            </div>
                                        </Col>
                                        <div className="col-xxl-3 ms-auto">
                                            <div className="mb-n5 pb-1 faq-img d-none d-xxl-block">
                                                <img src={faqImg} alt="" className="img-fluid"/>
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </Card>
                            <Row className="justify-content-evenly">
                                <Col lg={12}>
                                    <div className="mt-3">
                                        {/*<div className="d-flex align-items-center mb-2">*/}
                                        {/*    <div className="flex-shrink-0 me-1">*/}
                                        {/*        <i className="ri-question-line fs-24 align-middle  me-1" style={{"color": "#5e35b1"}}></i>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="flex-grow-1">*/}
                                        {/*        <h5 className="fs-16 mb-0 fw-semibold">My Active Plans</h5>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {isNotesSuccess  ? (
                                            (noteList || []).map((item, index) => (
                                                <React.Fragment key={index}>
                                                    <div className="accordion accordion-border-box" id="genques-accordion">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="genques-headingOne">
                                                                <button
                                                                    className={classnames(
                                                                        "accordion-button",
                                                                        "fw-medium",
                                                                        { collapsed: !col1 }
                                                                    )}
                                                                    type="button"
                                                                    onClick={t_col1}
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    {item.plan_name} ({item.plan_type_name})
                                                                </button>
                                                            </h2>
                                                            <Collapse isOpen={col1} className="accordion-collapse">
                                                                <div className="accordion-body">
                                                                    <Row>
                                                                        <Col><b>Total Amount: {item.amount}</b></Col>
                                                                        <Col><b>Duration: {item.duration} weeks</b></Col>
                                                                        <Col><b>ROI: {item.interest_info}</b></Col>
                                                                        <Col><b>Plan ID: {item.plan_id}</b></Col>
                                                                    </Row>
                                                                    <div className="table-responsive">
                                                                        <Table className="align-middle table-nowrap mb-0">
                                                                            <thead>
                                                                            <tr>
                                                                                <th scope="col">Due Date</th>
                                                                                <th scope="col">EMI Count</th>
                                                                                <th scope="col">Amount</th>
                                                                                <th scope="col">Paid Date</th>
                                                                                <th scope="col">Paid Mode</th>
                                                                                <th scope="col">Status</th>
                                                                            </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            {
                                                                                (item.emi || []).map((emi, ei) => (
                                                                                    <React.Fragment key={ei}>
                                                                                        {emi.is_paid  ? (
                                                                                            <tr>
                                                                                                <td>{emi.due_date}</td>
                                                                                                <td>{emi.due_count}</td>
                                                                                                <td>{emi.amount}</td>
                                                                                                <td>{new Date(emi.paid_date).toLocaleDateString()}</td>
                                                                                                <td>{emi.pay_mode}</td>

                                                                                                <td className={"text-success"}>{emi.status}</td>
                                                                                            </tr>
                                                                                        ) : (
                                                                                            <tr className={'text-muted'}>
                                                                                                <td>{emi.due_date}</td>
                                                                                                <td>{emi.due_count}</td>
                                                                                                <td>{emi.amount}</td>
                                                                                                <td>{emi.paid_date}</td>
                                                                                                <td>{emi.pay_mode}</td>
                                                                                                <td>{emi.status}</td>
                                                                                            </tr>
                                                                                        )}
                                                                                    </React.Fragment>

                                                                                ))
                                                                            }



                                                                            </tbody>
                                                                        </Table>
                                                                    </div>
                                                                </div>
                                                            </Collapse>
                                                        </div>


                                                    </div>
                                                    <br/>
                                                </React.Fragment>
                                            ))
                                        ) : (<Loader error={error} />)
                                        }
                                    </div>
                                </Col>




                            </Row>
                            <br/><br/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>);

}

export default MyCompletedPlans;