import React from 'react';
import { Col, Container, Form, Row } from 'reactstrap';

const Contact = () => {
    return (
        <React.Fragment>
            <section className="section" id="contact">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <div className="text-center mb-5">
                                <h3 className="mb-3 fw-semibold">Contact Details</h3>

                            </div>
                        </Col>
                    </Row>

                    <Row className="gy-4">
                        <Col lg={4}>
                            <div>
                                <div className="mt-4">
                                    <h5 className="fs-13 text-muted text-uppercase">Address :</h5>
                                    <div className="ff-secondary fw-semibold">Chennai</div>
                                </div>
                                <div className="mt-4">
                                    <h5 className="fs-13 text-muted text-uppercase">Contact Number:</h5>
                                    <div className="ff-secondary fw-semibold">+91-9944772292</div>
                                </div>
                                <div className="mt-4">
                                    <h5 className="fs-13 text-muted text-uppercase">Contact Email:</h5>
                                    <div className="ff-secondary fw-semibold">support@ashacapital.in</div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}></Col>
                        <Col lg={4}>
                            <div>
                                <div className="mt-4">
                                    <div className="fs-13 text-muted text-uppercase">WhatsApp: +91-9944772292
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <a href={"https://t.me/AshaCapital_official"} target={"_blank"} style={{'color': 'skyblue'}}><b>Telegram : AshaCapital_official</b></a>
                                </div>
                                <div className="mt-4">
                                    <a href={"https://www.facebook.com/profile.php?id=61550959946611&mibextid=9R9pXO"} target={"_blank"} style={{'color': 'blue'}}><b>Facebook : AshaCapital.official </b></a>
                                </div>
                                <div className={"mt-4"}>
                                    <a href={"https://instagram.com/ashacapital_official?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D"} target={"_blank"} style={{'color': 'pink'}}><b>Instagram: ashacapital_official </b></a>
                                </div>
                                <div className={"mt-4"}>
                                    <a href={"#"} ><b>Play Store: Asha Capital
                                    </b></a>
                                </div>

                            </div>
                        </Col>


                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default Contact;