import {Card, CardBody, CardFooter, CardHeader, Col, Collapse, Container, Row} from "reactstrap";
import faqImg from "../../assets/images/faq-img.png";
import classnames from "classnames";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {APIClient} from "../../helpers/api_helper";
import Loader from "../../Components/Common/Loader";

const CurrentPlans = () => {
    const api = new APIClient();
    const [projectList, setProjectList] = useState([]);
    const [error, setError] = useState({});
    let [isProjectsSuccess, setisProjectsSuccess] = useState(true);

    async function getProjectsApi() {
        try{
            const res = await api.get(`/active-plans`);
            console.log(res);
            setProjectList(res);
            setisProjectsSuccess(true);
        }catch (e){
            console.log(e);
            setError('Invalid Request');
            setisProjectsSuccess(false);
        }
    }

    function getProjectsList(){
        getProjectsApi().then();
    }

    // useEffect(() => {
    //     console.log('default');
    //     getNotesList();
    // }, []);


    useEffect(() => {
        console.log('page');
        getProjectsList();
    }, []);

    const [col1, setcol1] = useState(true)
    const [col2, setcol2] = useState(false)
    const [col3, setcol3] = useState(false)
    const [col4, setcol4] = useState(false)

    const [col5, setcol5] = useState(false)
    const [col6, setcol6] = useState(true)
    const [col7, setcol7] = useState(false)
    const [col8, setcol8] = useState(false)

    const [col9, setcol9] = useState(true)
    const [col10, setcol10] = useState(false)
    const [col11, setcol11] = useState(false)
    const [col12, setcol12] = useState(false)


    const t_col1 = () => {
        setcol1(!col1)
        setcol2(false)
        setcol3(false)
        setcol4(false)

    }

    const t_col2 = () => {
        setcol2(!col2)
        setcol1(false)
        setcol3(false)
        setcol4(false)

    }

    const t_col3 = () => {
        setcol3(!col3)
        setcol1(false)
        setcol2(false)
        setcol4(false)

    }

    const t_col4 = () => {
        setcol4(!col4)
        setcol1(false)
        setcol2(false)
        setcol3(false)
    }

    const t_col5 = () => {
        setcol5(!col5)
        setcol6(false)
        setcol7(false)
        setcol8(false)

    }

    const t_col6 = () => {
        setcol6(!col6)
        setcol5(false)
        setcol7(false)
        setcol8(false)

    }

    const t_col7 = () => {
        setcol7(!col7)
        setcol6(false)
        setcol5(false)
        setcol8(false)

    }

    const t_col8 = () => {
        setcol8(!col8)
        setcol5(false)
        setcol6(false)
        setcol7(false)
    }

    const t_col9 = () => {
        setcol9(!col9)
        setcol10(false)
        setcol11(false)
        setcol12(false)

    }

    const t_col10 = () => {
        setcol10(!col10)
        setcol11(false)
        setcol12(false)
        setcol9(false)

    }

    const t_col11 = () => {
        setcol11(!col11)
        setcol9(false)
        setcol10(false)
        setcol12(false)

    }

    const t_col12 = () => {
        setcol12(!col12)
        setcol9(false)
        setcol10(false)
        setcol11(false)
    }

    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card className="rounded-0 mx-n4 mt-n4 border-top" style={{backgroundColor:"rgba(94, 53, 177, 0.18)"}}>
                                <div className="px-4">
                                    <Row>
                                        <Col xxl={5} className="align-self-center">
                                            <div className="py-4">
                                                <h4 className="display-6 " style={{color:"#5e35b1"}}>Current Plans</h4>
                                                {/*<p className=" fs-15 mt-3" style={{color:"#5e35b1"}}>You can always contact us or email us. We will answer you shortly!</p>*/}
                                                {/*<div className="hstack flex-wrap gap-2">*/}
                                                {/*    <button type="button" className="btn btn-primary btn-label rounded-pill"><i className="ri-mail-line label-icon align-middle rounded-pill fs-16 me-2"></i> Email Us</button>*/}
                                                {/*    <button type="button" className="btn btn-info btn-label rounded-pill"><i className="ri-twitter-line label-icon align-middle rounded-pill fs-16 me-2"></i> Send Us Tweet</button>*/}
                                                {/*</div>*/}
                                            </div>
                                        </Col>
                                        {/*<div className="col-xxl-3 ms-auto">*/}
                                        {/*    <div className="mb-n5 pb-1 faq-img d-none d-xxl-block">*/}
                                        {/*        <img src={faqImg} alt="" className="img-fluid"/>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </Row>
                                </div>
                            </Card>
                            <Row className="justify-content-evenly">
                                <Col lg={12}>
                                    <div className="mt-3">
                                        {/*<div className="d-flex align-items-center mb-2">*/}
                                        {/*    <div className="flex-shrink-0 me-1">*/}
                                        {/*        <i className="ri-question-line fs-24 align-middle  me-1" style={{"color": "#5e35b1"}}></i>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="flex-grow-1">*/}
                                        {/*        <h5 className="fs-16 mb-0 fw-semibold">Income Type</h5>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="accordion accordion-border-box" id="genques-accordion">

                                            {isProjectsSuccess  ? (

                                                (projectList || []).map((item, index) => (
                                                    <React.Fragment key={index}>

                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="genques-headingOne">
                                                                <button
                                                                    className={classnames(
                                                                        "accordion-button",
                                                                        "fw-medium",
                                                                        { collapsed: !col1 }
                                                                    )}
                                                                    type="button"
                                                                    onClick={t_col1}
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    {item.name}
                                                                </button>
                                                            </h2>
                                                            <Collapse isOpen={col1} className="accordion-collapse">
                                                                <div className="accordion-body" style={{background: 'aliceblue'}}>
                                                                    <Row>
                                                                        {
                                                                            (item.plans || []).map((i, ii)=>(
                                                                                <Col xxl={3} sm={6} key={ii} className="project-card" style={{textAlign:'center'}}>
                                                                                    <Card>
                                                                                        <CardHeader><h3><b>{i.name}</b></h3></CardHeader>
                                                                                        <CardBody>
                                                                                            <div>{i.description}</div>
                                                                                            <div>Amount: {i.amount}</div>
                                                                                            <div>Duration : {i.duration} Weeks</div>
                                                                                            <div>Payout : {i.payout}</div>
                                                                                            <div>{i.interest_info}</div>
                                                                                        </CardBody>
                                                                                        <CardFooter><Link to={`/subscribe/${i.id}`}><button type="button" className="btn btn-success w-sm me-1">View</button></Link></CardFooter>
                                                                                    </Card>
                                                                                </Col>
                                                                            ))
                                                                        }
                                                                    </Row>
                                                                </div>
                                                            </Collapse>
                                                        </div>


                                                    </React.Fragment>
                                                ))
                                            ) :(<Loader error={error} />)}
                                        </div>
                                    </div>
                                </Col>



                            </Row>
                            <br/><br/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>);

}

export default CurrentPlans;