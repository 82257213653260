import React from "react";
import { Redirect } from "react-router-dom";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// Landing Index
import OnePage from "../pages/Landing/OnePage";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";

import IncomeType from "../Components/IncomeType";
import EmployeeListView from "../Views/Employee/ListView";
import OfferPlans from "../Views/OfferPlans";
import EmployeeAddForm from "../Views/Employee/Forms/Create";
import SupportView from "../Views/Support";
import CompletedPlans from "../Views/CompletedPlans";
import CurrentPlans from "../Views/CurrentPlans";
import AllPlans from "../Views/AllPlans";
import SubscribeView from "../Views/Subscribe";
import PendingApprovalsView from "../Views/PendingApprovals";
import ReferralsListView from "../Views/Referrals";
import FeedbackListView from "../Views/Feedback";
import ProfileView from "../Views/Profile";
import VerifyUser from "../pages/Authentication/VerifyUser";
import ChangePassword from "../pages/Authentication/ChangePassword";
import UpdatePassword from "../pages/Authentication/UpdatePassword";
import PendingApprovalView from "../Views/PendingApprovals/PendingApprovalView";
import SuggestionsListView from "../Views/Suggestions";
import Plans from "../Views/Plans";
import PlanAddForm from "../Views/Plans/Forms/Create";
import PlanView from "../Views/Plans/PlanView";
import ReferralsPayment from "../Views/Referrals/ReferralsPayment";
import ReferralPaymentView from "../Views/Referrals/ReferralPaymentView";
import PendingPayments from "../Views/PendingPayments";
import PendingPaymentsView from "../Views/PendingPayments/PendingPaymentsView";
import MyActivePlans from "../Views/MyActivePlans";
import MyReferralsView from "../Views/Referrals/MyReferralsView";
import ResetPassword from "../Views/Employee/ResetPassword";
import ProfileEditRequest from "../Views/Profile/ProfileEditRequest";
import RejectedSubscription from "../Views/Subscribe/RejectedSubscription";
import RejectedSubscriptionView from "../Views/Subscribe/RejectedSubscriptionView";
import MyOffers from "../Views/SpecialOffers/MyOffers";
import SpecialOffers from "../Views/SpecialOffers/SpecialOffers";
import MyCompletedPlans from "../Views/MyCompletedPlans";
import MySuggestions from "../Views/Suggestions/MySuggestions";
import SuggestionForm from "../Views/Suggestions/SuggestionForm";
import SuggestionView from "../Views/Suggestions/SuggestionView";
import RatingListView from "../Views/Rating";
import MyRatingList from "../Views/Rating/MyRatingList";
import RatingForm from "../Views/Rating/RatingForm";
import MyRating from "../Views/Rating/MyRating";
import SupportForm from "../Views/Support/SupportForm";
import SwitchView from "../Views/Switch";
import Reports from "../Views/Reports";

const authProtectedRoutes = [
  { path: "/profile", component: ProfileView },
  { path: "/income-type", component:IncomeType},
  { path: "/users", component:EmployeeListView},
  { path: "/users/forms/create", component:EmployeeAddForm},
  { path: "/support", component:SupportView},
  { path: "/admin-support", component:SupportForm},
  { path: "/special-offers", component: SpecialOffers},
  { path: "/my-offers", component: MyOffers},
  { path: "/offer-plans", component: OfferPlans},
  { path: "/current-plans", component:CurrentPlans},
  { path: "/completed-plans", component:CompletedPlans},
  { path: "/all-plans", component:AllPlans},
  { path: "/subscribe/:subscribeId", component:SubscribeView},
  { path: "/rejected-subscription", component:RejectedSubscription},
  { path: "/rejected-subscription/:subscribeId", component:RejectedSubscriptionView},
  { path: "/pending-approvals", component:PendingApprovalsView},
  { path: "/pending-approvals/:approvalId", component:PendingApprovalView},
  { path : "/pending-payments", component:PendingPayments},
  {path : "/pending-payments/:userId", component:PendingPaymentsView},
  { path: "/referrals", component:ReferralsListView},
  { path: "/my-referrals", component:MyReferralsView},
  { path: "/referrals-payments", component:ReferralsPayment},
  { path: "/referral-payments-view/:paymentId", component:ReferralPaymentView},
  { path: "/feedbacks", component:RatingListView},
  { path: "/my-feedbacks", component:MyRating},
  { path: "/my-feedbacks/form/create", component:RatingForm},
  { path: "/suggestions", component:SuggestionsListView},
  { path: "/my-suggestions", component:MySuggestions},
  { path: "/suggestions/:suggestionId", component:SuggestionView},
  { path: "/suggestion/form/create", component:SuggestionForm},
  { path: "/plans", component: Plans},
  { path: "/plans/form/create", component: PlanAddForm},
  { path: "/plans/:planId", component:PlanView},
  { path: "/my-active-plans", component:MyActivePlans},
  { path: "/my-completed-plans", component:MyCompletedPlans},
  {path: "/reports", component:Reports},
  { path: "/index", component: ProfileView },
  {path: "/profile-edit-request", component:ProfileEditRequest},
  { path: "/reset-password", component: ResetPassword},

  //User Profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name

  {path: "/switch", component:SwitchView},
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/switch" />,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPasswordPage },
  { path: "/register", component: Register },
  { path: "/otp-verify", component: VerifyUser },
  { path: "/change-password", component: ChangePassword},
  { path: "/update-password", component: UpdatePassword},
  { path: "/landing", component: OnePage},

  //AuthenticationInner pages
  // { path: "/auth-signin-basic", component: BasicSignIn },
  // { path: "/auth-signin-cover", component: CoverSignIn },
  // { path: "/auth-signup-basic", component: BasicSignUp },
  // { path: "/auth-signup-cover", component: CoverSignUp },
  // { path: "/auth-pass-reset-basic", component: BasicPasswReset },
  // { path: "/auth-pass-reset-cover", component: CoverPasswReset },
  // { path: "/auth-lockscreen-basic", component: BasicLockScreen },
  // { path: "/auth-lockscreen-cover", component: CoverLockScreen },
  // { path: "/auth-logout-basic", component: BasicLogout },
  // { path: "/auth-logout-cover", component: CoverLogout },
  // { path: "/auth-success-msg-basic", component: BasicSuccessMsg },
  // { path: "/auth-success-msg-cover", component: CoverSuccessMsg },
  // { path: "/auth-twostep-basic", component: BasicTwosVerify },
  // { path: "/auth-twostep-cover", component: CoverTwosVerify },
  // { path: "/auth-404-basic", component: Basic404 },
  // { path: "/auth-404-cover", component: Cover404 },
  // { path: "/auth-404-alt", component: Alt404 },
  // { path: "/auth-500", component: Error500 },
  // { path: "/pages-maintenance", component: Maintenance },
  // { path: "/pages-coming-soon", component: ComingSoon },
  //
  // { path: "/landing", component: OnePage },
  // { path: "/nft-landing", component: NFTLanding },
  //
  // { path: "/auth-pass-change-basic", component: BasicPasswCreate },
  // { path: "/auth-pass-change-cover", component: CoverPasswCreate },
  // { path: "/auth-offline", component: Offlinepage },

];

export { authProtectedRoutes, publicRoutes };