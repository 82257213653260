import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
//Import Flatepicker
import Select from "react-select";
import {APIClient} from "../../../../connector/client";

const PlanAddForm = (props) => {
    const api = new APIClient();
    const SingleOptions = [
        { value: 'Watches', label: 'Watches' },
        { value: 'Headset', label: 'Headset' },
        { value: 'Sweatshirt', label: 'Sweatshirt' },
        { value: '20% off', label: '20% off' },
        { value: '4 star', label: '4 star' },
    ];

    const [selectedMulti, setselectedMulti] = useState(null);

    const handleMulti = (selectedMulti) => {
        setselectedMulti(selectedMulti);
    }

    //Dropzone file upload
    const [selectedFiles, setselectedFiles] = useState([]);
    const [files, setFiles] = useState([]);

    const handleAcceptedFiles = (files) => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );
        setselectedFiles(files);
    }

    /**
     * Formats the size
     */
    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    const [inputFields, setInputFields] = useState({'type_id':"1", 'payout':'Saturday and Sunday only'});
    const [attachments, setAttachments] = useState();

    const[type_id, setTypeId] = useState(1);
    const[payout, setPayout] = useState('Saturday and Sunday Only');
    const[name, setName] = useState('');
    const[description, setDescription] = useState('');
    const[amount, setAmount] = useState('');
    const[emi_amount, setEmiAmount] = useState('');
    const[duration, setDuration] = useState('');
    const[interest_info, setInterestInfo] = useState('');
    const[commission_amount, setCommissionAmount] = useState('');

    const handleInputChange = (event) => {
        inputFields[event.target.name] = event.target.value;
        //inputFields['full_name'] = inputFields['first_name'] + ' ' + inputFields['last_name'];
        setInputFields(inputFields);
        console.log(inputFields);
    }

    const handleFileChange = (event) =>{
        //let tfiles = event.target.files;
        //console.log(tfiles);
        //attachments.push(tfiles[0]);
        setAttachments(event.target.files[0]);
    }

    const handleCancel = (event) => {
        props.history.push("/plans");
    }

    async function postApi(data) {
        var formData = new FormData();
        var data_obj = {};
        data_obj['name'] = name;
        data_obj['type_id'] = type_id;
        data_obj['payout'] = payout;
        data_obj['description'] = description;
        data_obj['amount'] = amount;
        data_obj['emi_amount'] = emi_amount;
        data_obj['duration'] = duration;
        data_obj['interest_info'] = interest_info;
        data_obj['commission_amount'] = commission_amount;
        formData.append("data", JSON.stringify(data_obj));
        console.log(attachments);
        formData.append('file', attachments);
        try{
            const response = await api.create(`/plan`, data_obj);
            console.log(response);
            return response;
        }catch (e){
            console.log(e);
        }
    }

    function postData(data){
        postApi(data).then((response) => {
            props.history.push("/plans");

        });
    }

    const handleSubmit = (event) =>{
        var data = new FormData();
        data.append("data", JSON.stringify(inputFields));
        data.append('file', attachments);
        postData(data);

    }



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Create Plan" pageTitle="Plans" />
                    <form id="myForm" method="post" action={'#'} encType="multipart/form-data" onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                        return false;
                    }}>
                        <Row>
                            <Col lg={8}>
                                <Row>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Plan Name</Label>
                                                        <Input type="text" className="form-control" id="project-title-input"
                                                               placeholder="Enter Plan Name" name={'name'} value={name}
                                                               onChange={event => setName(event.target.value)} />
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Plan Type</Label>
                                                        <select value={type_id} name={'type_id'} className="form-select" data-choices data-choices-search-false onChange={event => setTypeId(event.target.value)}>
                                                            <option value={1}>Safe Plans</option>
                                                            <option value={2}>High Risk Plans</option>
                                                        </select>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Description</Label>
                                                        <Input type="text" className="form-control" id="project-title-input"
                                                               placeholder="Enter Plan Description" name={'description'} value={description}
                                                               onChange={event => setDescription(event.target.value)}/>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Amount</Label>
                                                        <Input type="text" className="form-control" id="project-title-input"
                                                               placeholder="Enter Amount" name={'amount'} value={amount}
                                                               onChange={event => setAmount(event.target.value)}/>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">EMI Amount</Label>
                                                        <Input type="text" className="form-control" id="project-title-input"
                                                               placeholder="Enter EMI Amount" name={'emi_amount'} value={emi_amount}
                                                               onChange={event => setEmiAmount(event.target.value)}/>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Duration</Label>
                                                        <Input type="text" className="form-control" id="project-title-input"
                                                               placeholder="Enter Duration in weeks" name={'duration'} value={duration}
                                                               onChange={event => setDuration(event.target.value)}/>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Interest Info</Label>
                                                        <Input type="text" className="form-control" id="project-title-input"
                                                               placeholder="Enter Interest Info" name={'interest_info'} value={interest_info}
                                                               onChange={event => setInterestInfo(event.target.value)}/>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Referral Commission</Label>
                                                        <Input type="text" className="form-control" id="project-title-input"
                                                               placeholder="Enter Referral Commission Amount" name={'commission_amount'} value={commission_amount}
                                                               onChange={event => setCommissionAmount(event.target.value)}/>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="project-title-input">Payout</Label>
                                                        <Input type="text" readOnly={true} className="form-control" id="project-title-input"
                                                               placeholder="Enter Payout" name={'payout'} value={payout}
                                                               onChange={event => setPayout(event.target.value)}/>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Row>

                                <Row>
                                    <Col>
                                        <div className="mb-4">
                                            <button onClick={event => handleCancel(event)} type="button" className="btn btn-danger w-sm me-1">Cancel</button>
                                            <button type="submit" className="btn btn-success w-sm me-1">Submit</button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    </form>


                </Container>
            </div>
        </React.Fragment>
    );
};

export default PlanAddForm;